import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ICourseCLO, ICourse } from "../../../report/model/SOEvidence";
import { SOEvidenceService,  CourseType } from "../../../report/service/SOEvidenceService";
import { ISurveyType } from "../../model/Survey";
import {SurveyService, CourseInfoType} from '../../service/SurveyService';
import { MASTER_X_API_KEY } from "../../../../constants";

type StateCourseCLOType = {
    data: ICourseCLO[],
    errMessage: string
}

// const useFetchCourseInfo = (courseCode: string, surveyId: number) => {
//     console.log("I am in useFetchCourseInfo.....");
//     console.log("CourseCode :" + courseCode + " , surveyid : " + surveyId);
//     const [courseCLO, setCourseCLO] = useState<StateCourseCLOType>({
//         data: [],
//         errMessage: ""
//     });

//     const [course, setCourse] = useState<CourseType>({
//         data: {} as ICourse,
//         errMessage: ""
//     });    

//     const [surveyLinkDetail, setSurveyLinkDetail] = useState<any>({
//         data: {} as ISurveyType,
//         errMessage: ""
//     }); 

//     const dispatch = useDispatch();
//     let accessToken = MASTER_X_API_KEY
//     //@fetching course clo by course code
//     const fetchCourseCLO = async () => {
//         if (accessToken !== null) {
//             const courseCloData = await SOEvidenceService.getCourseCLO(accessToken, courseCode);
//             //console.log("My CLO by coureCode : " + courseCloData);
//             setCourseCLO(courseCloData);
//         }
//     }

//     //@fetching survey link detail by surveyId
//     const fetchSurveyLinkDetail = async () => {
//         if (accessToken !== null) {
//             const surveyDataResponse = await SurveyService.getSurveyLinkDetailBySurveyId(surveyId);
//             setSurveyLinkDetail(surveyDataResponse);
//             //console.log("I am in getSurveyLinkDetailsBySurveyId :" + JSON.stringify(surveyLinkDetail));
//         }
//     }

//      //@fetching course by course code
//      const fetchCourseByCourseCode = async () => {
//         if (accessToken !== null) {
//             const courseData = await SOEvidenceService.getCourseByCourseCode(accessToken, courseCode);
//             setCourse(courseData);
//         }
//     }

//     useEffect(() => {
//         fetchSurveyLinkDetail();
//         fetchCourseByCourseCode();
//         fetchCourseCLO();
        
//      }, [])

//     return { course, courseCLO, surveyLinkDetail };
// }


const useFetchCourseInfo = (courseCode: string, surveyId: number) => {
    // console.log("I am in useFetchCourseInfo.....");
    // console.log("CourseCode :" + courseCode + " , surveyId : " + surveyId);

    // const [isCloLoadingStatus,setIsCloLoadingStatus] = useState(false);
    const [isCourseLoadingStatus,setIsCourseLoadingStatus] = useState(false);
    // const [courseCLO, setCourseCLO] = useState<StateCourseCLOType>({
    //     data: [],
    //     errMessage: ""
    // });

    // const [course, setCourse] = useState<CourseType>({
    //     data: {} as ICourse,
    //     errMessage: ""
    // });

    const [surveyLinkDetail, setSurveyLinkDetail] = useState<any>({
        data: {} as ISurveyType,
        errMessage: ""
    });

    const [courseInfo, setCourseInfo] =useState<CourseInfoType>();
    const dispatch = useDispatch();
    let accessToken = MASTER_X_API_KEY;

    
 

        // Fetching Survey Link Detail by survey ID
        const fetchSurveyLinkDetail = () => {
            console.log("Calling fetchSurveyLinkDetail...");
            if (accessToken !== null && surveyId !== 0) {
                console.log("Calling fetchSurveyLinkDetail by Survey Id: "+ surveyId);
                SurveyService.getSurveyLinkDetailBySurveyId(surveyId)
                    .then((surveyDataResponse) => {
                        setSurveyLinkDetail({ data: surveyDataResponse, errMessage: "" });
                    })
                    .catch((error) => {
                        setSurveyLinkDetail({ data: {}, errMessage: error.message || "Error fetching Survey Link" });
                    });
            }
        };



        //Fetching Course Information
        const fetchCourseInfo = () => {
            console.log("Survey link detail...." +JSON.stringify(surveyLinkDetail.data.data));
            if (accessToken !== null && surveyLinkDetail.data.data ) {
                SurveyService.getCourseInfoForCLOSurvey(courseCode,surveyLinkDetail.data.data.sectionNumber,surveyLinkDetail.data.data?.departmentId ,surveyLinkDetail.data.data.programId || 17,surveyLinkDetail.data.data.campusId,surveyLinkDetail.data.data.collegeId,surveyLinkDetail.data.data.semester,surveyLinkDetail.data.data.year)
                    .then((courseInfoResponse) => {
                        setCourseInfo(courseInfoResponse);
                        setIsCourseLoadingStatus(true);
                    })
                    .catch((error) => {
                        setCourseInfo({data: {
                            courseCode: "",
                            courseName: "",
                            section : 0,
                            departmentName: "",
                            programName: "",
                            campusName: "",
                            collegeName: "",
                            semester : "",
                            year: 0,
                            courseCLO: []
                        }  ,errMessage: error.message || "Error fetching Survey Link" });
                    });
            }
        };
    
    useEffect(() => {
        if (courseCode !== null && surveyId !== 0) {
            fetchSurveyLinkDetail(); // Fetch survey link detail
            console.log("useEffect surveyLinkDatail.data.data:" + JSON.stringify(surveyLinkDetail.data.data))
        }
    }, [courseCode, surveyId]);

    useEffect(() =>{
        if(surveyLinkDetail.data && surveyLinkDetail.data.data){
            console.log("calling fetchcourseinfo()");
            fetchCourseInfo();
        }
    },[surveyLinkDetail.data])
  

    return { surveyLinkDetail, courseInfo ,isCourseLoadingStatus};
};




export default useFetchCourseInfo;