import axios from 'axios'
import { IScheduleCourseType } from "../model/ScheduleCourse"
import readAPI from '../data/api/read'
import axiosInstance from '../../../Service/interceptor'


export type scheduleCourseType = {
    data: IScheduleCourseType[],
    errMessage: ""
}
export type scheduleCourseStatisticType = {
    data: {},
    errMessage:""
}

interface ICampusStatistic{
    campusId: string,
    campusName: string,
    totalSection: number
}
interface IDepartmentStatistic{
    departmentId: string,
    departmentName:string,
    totalSection: number
}
export type sectionStatisticByCollegeType = {
    college:{
        collegeId: string,
        collegeName: string,
        campus: ICampusStatistic[],
        department: IDepartmentStatistic[]
    },
    errMessage:""
}

export type sessionTypeT = {
    data: {},
    errMessage:""
}


//@ calling get college api ============================================================

async function getAllScheduleCourse(): Promise<scheduleCourseType> {
    let url: string = readAPI.ScheduleOfferedcourses;
    const scheduleCourses: scheduleCourseType = {
        data: [],
        errMessage: ""
    };
    try {
        const res = await axiosInstance.get(url);
        scheduleCourses.data = res.data.obj;
        if (res.data.code !== "200.200") {
            scheduleCourses.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        scheduleCourses.errMessage = err.message;
    }
    return scheduleCourses;
}

async function getScheduleCourseStatistic(): Promise<scheduleCourseStatisticType> {
    let url: string = readAPI.ScheduleCourseStatistic;
    // let url:string = "/dummyData/scheduleCourseCount.json"
    const scheduleCourseStatistic: scheduleCourseStatisticType = {
        data: [],
        errMessage: ""
    };
    try {
        const res = await axiosInstance.get(url);
        scheduleCourseStatistic.data = res.data.obj;
        if (res.data.code !== "200.200") {
            scheduleCourseStatistic.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        scheduleCourseStatistic.errMessage = err.message;
    }
    return scheduleCourseStatistic;
}

async function getSessionType(): Promise<sessionTypeT> {
    let url: string = readAPI.SessionType;
    // let url:string = "/dummyData/scheduleCourseCount.json"
    const sessionTypeList: sessionTypeT = {
        data: [],
        errMessage: ""
    };
    try {
        const res = await axiosInstance.get(url);
        
        if(res.data.code == "200.200"){
            sessionTypeList.data = res.data.obj;
        }else{
        // if (res.data.code !== "200.200") {
            sessionTypeList.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        sessionTypeList.errMessage = err.message;
    }
    return sessionTypeList;
}

async function getScheduleCourseStatisticByCollege(collegeId: string): Promise<sectionStatisticByCollegeType> {
    let url: string = readAPI.ScheduleCourseStatisticByCollegeId + "?collegeId="+collegeId;
    const scheduleSectionStatisticByCollege: sectionStatisticByCollegeType = {
        college:{
            collegeId: "",
            collegeName:"",
            campus: [] as ICampusStatistic[],
            department: [] as IDepartmentStatistic[],
        },
        errMessage:""
    };

    try {
        const res = await axiosInstance.get(url);
        scheduleSectionStatisticByCollege.college = res.data.obj;
        if (res.data.code !== "200.200") {
            scheduleSectionStatisticByCollege.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        scheduleSectionStatisticByCollege.errMessage = err.message;
    }
    return scheduleSectionStatisticByCollege;
}




export const ScheduleCourseService = {
    getAllScheduleCourse,
    getScheduleCourseStatistic,
    getSessionType,
    getScheduleCourseStatisticByCollege,
}