const initialState = {
    data: null,
  };
  
  const assignTaskReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case 'ASSIGNTASK_REPORT_DATA':
        return {
          ...state,
          data: action.payload.data,
        };
      case 'LOGOUT':
        return initialState;
      default:
        return state;
    }
  };
  
  export default assignTaskReducer;

 

  