import { ThunkAction } from "redux-thunk";
import { RootState } from "../store";
import { AnyAction, Dispatch } from 'redux';
import { ScheduleCourseService } from "../../Apps/scheduleCourse/service/ScheduleCourseService";

export const COURSE_COORDINATOR = 'COURSE_COORDINATOR';
export const ACADEMIC_YEAR = 'ACADEMIC_YEAR';

export const FETCH_SCHEDULE_COURSE_DATA_REQUEST = 'FETCH_SCHEDULE_COURSE_DATA_REQUEST';
export const FETCH_SCHEDULE_COURSE_DATA_SUCCESS = 'FETCH_SCHEDULE_COURSE_DATA_SUCCESS';
export const FETCH_SCHEDULE_COURSE_DATA_FAILURE = 'FETCH_SCHEDULE_COURSE_DATA_FAILURE';
export const SCHEDULE_SECTION_STATISTIC_DATA = 'SCHEDULE_SECTION_STATISTIC_DATA';
export const UPDATE_SCHEDULE_SECTION_STATISTIC_DATA = 'UPDATE_SCHEDULE_SECTION_STATISTIC_DATA';
export const courseCoordinator = (data: any) => ({
    type: COURSE_COORDINATOR,
    payload: {data}
  })

  export const academicYear = (data: any) => ({
    type: ACADEMIC_YEAR,
    payload: {data}
  })

  export const scheduleSectionStatisticDataAction = (data: any) => ({
    type: SCHEDULE_SECTION_STATISTIC_DATA,
    payload: {data}
  })

  export const updateScheduleSectionStatisticDataAction = (data: any) => ({
    type: UPDATE_SCHEDULE_SECTION_STATISTIC_DATA,
    payload: data
  })
  //@ function for fetching COURSE DETAILS data ============================================================

export const fetchScheduleCourseRedux = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (dispatch: Dispatch): Promise<void> => {
      dispatch({ type: FETCH_SCHEDULE_COURSE_DATA_REQUEST });
      try {
          const response = await ScheduleCourseService.getAllScheduleCourse();
          dispatch({ type: FETCH_SCHEDULE_COURSE_DATA_SUCCESS, payload: response.data });
      } catch (error: any) {
          dispatch({ type: FETCH_SCHEDULE_COURSE_DATA_FAILURE, payload: error.message });
      }
  };
}