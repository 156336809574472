import { ThunkAction } from "redux-thunk";
import { RootState } from "../store";
import { AnyAction, Dispatch } from 'redux';
import { AssessmentService } from "../../Apps/assessment/service/AssessmentService";

export const FETCH_SCHEDULE_COURSE_DATA_REQUEST = 'FETCH_SCHEDULE_COURSE_DATA_REQUEST';
export const FETCH_SCHEDULE_COURSE_DATA_SUCCESS = 'FETCH_SCHEDULE_COURSE_DATA_SUCCESS';
export const FETCH_SCHEDULE_COURSE_DATA_FAILURE = 'FETCH_SCHEDULE_COURSE_DATA_FAILURE';

export const FETCH_ASSESSMENT_BY_LOGGEDIN_USER_DATA_REQUEST = 'FETCH_ASSESSMENT_BY_LOGGEDIN_USER_DATA_REQUEST';
export const FETCH_ASSESSMENT_BY_LOGGEDIN_USER_DATA_SUCCESS = 'FETCH_ASSESSMENT_BY_LOGGEDIN_USER_DATA_SUCCESS';
export const FETCH_ASSESSMENT_BY_LOGGEDIN_USER_DATA_FAILURE = 'FETCH_ASSESSMENT_BY_LOGGEDIN_USER_DATA_FAILURE';
export const ADD_ASSESSMENT_DATA = 'ADD_ASSESSMENT_DATA'
export const UPDATE_ASSESSMENT_DATA = 'UPDATE_ASSESSMENT_DATA'
export const DELETE_ASSESSMENT_DATA = 'DELETE_ASSESSMENT_DATA'
export const CLEAR_ASSESSMENT_DATA = 'CLEAR_ASSESSMENT_DATA'



export const fetchAssessmentByLoggedInUserRedux = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (dispatch: Dispatch): Promise<void> => {
      dispatch({ type: FETCH_ASSESSMENT_BY_LOGGEDIN_USER_DATA_REQUEST });
      try {
          const response = await AssessmentService.getAllAssessmentByLoggedInUser();
          dispatch({ type: FETCH_ASSESSMENT_BY_LOGGEDIN_USER_DATA_SUCCESS, payload: response.data });
      } catch (error: any) {
          dispatch({ type: FETCH_ASSESSMENT_BY_LOGGEDIN_USER_DATA_FAILURE, payload: error.message });
      }
  };
}


export const addAssessmentDetailsRedux = (data: any) => ({
  type: ADD_ASSESSMENT_DATA,
  payload: { data }
});

export const clearAssessmentDataFromRedux = () => ({
  type: CLEAR_ASSESSMENT_DATA
});




export const updateAssessmentDetailsRedux = (index: number, updatedAssessmentDetails: any) => ({
  type: UPDATE_ASSESSMENT_DATA,
  payload: { index, updatedAssessmentDetails },
});

export const deleteAssessmentDetailsRedux = (id: number ) => ({
  type: DELETE_ASSESSMENT_DATA,
  payload: { id }
});

// export const assessmentByLoggedInUserData = (data: any) => ({
//   type: 'ASSESSMENT_BY_LOGGEDIN_USER',
//   payload: {data}
// })