interface TajweedCoreReducerState {
    college: any | null;
    university: any | null;
    campus: any | null;
    department: any | null;
    program: any | null;
    programType: any | null;
    programGoal: any | null;
    loading: any;
}

const initialState: TajweedCoreReducerState = {
    college: null,
    university: null,
    campus: null,
    department: null,
    program: null,
    programType: null,
    programGoal: null,
    loading: false
};

const tajweedReducer = (state = initialState, action: any): TajweedCoreReducerState => {
    switch (action.type) {
        //COLLEGE
        case 'ADD_COLLEGE_DATA':
            return {
                ...state,
                college: [...state.college, action.payload.data]
            }
        case 'UPDATE_COLLEGE_DATA':
            return {
                ...state,
                college: state.college.map((college: any, index: any) =>
                    index === action.payload.index ? { ...college, ...action.payload.updatedCollege } : college
                ),
            };
        case 'DELETE_COLLEGE_DATA':
            return {
                ...state,
                college: state.college.filter((college: any, idx: number) => college.collegeId !== action.payload.id),
            };
        case 'FETCH_COLLEGE_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                college: action.payload,
            };

        case 'COLLEGE':
            return {
                ...state,
                college: action.payload.data,
            };
        // UNIVERSITY
        case 'ADD_UNIVERSITY_DATA':
            return {
                ...state,
                university: [...state.university, action.payload.data]
            }
        case 'UPDATE_UNIVERSITY_DATA':
            return {
                ...state,
                university: state.university.map((university: any, index: any) =>
                    index === action.payload.index ? { ...university, ...action.payload.updatedUniversity } : university
                ),
            };
        case 'DELETE_UNIVERSITY_DATA':
            return {
                ...state,
                university: state.university.filter((university: any, idx: number) => university.universityId !== action.payload.id),
            };
        case 'FETCH_UNIVERSITY_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                university: action.payload,
            };
        case 'UNIVERSITY':
            return {
                ...state,
                university: action.payload.data
            }
        //CAMPUS

        case 'ADD_CAMPUS_DATA':
            return {
                ...state,
                campus: [...state.campus, action.payload.data]
            }
        case 'UPDATE_CAMPUS_DATA':
            return {
                ...state,
                campus: state.campus.map((campus: any, index: any) =>
                    index === action.payload.index ? { ...campus, ...action.payload.updatedCampus } : campus
                ),
            };
        case 'DELETE_CAMPUS_DATA':
            return {
                ...state,
                campus: state.campus.filter((campus: any, idx: number) => campus.campusId !== action.payload.id),
            };
        case 'FETCH_CAMPUS_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                campus: action.payload,
            };

        // case 'CAMPUS':
        //     return {
        //         ...state,
        //         campus: action.payload.data
        //     }
        // case 'DEPARTMENT':
        //     return {
        //         ...state,
        //         department: action.payload.data
        //     }

        //DEPARTMENT
        case 'ADD_DEPARTMENT_DATA':
            return {
                ...state,
                department: [...state.department, action.payload.data]
            }
        case 'UPDATE_DEPARTMENT_DATA':
            return {
                ...state,
                department: state.department.map((department: any, index: any) =>
                    index === action.payload.index ? { ...department, ...action.payload.updatedDepartment } : department
                ),
            };
        case 'DELETE_DEPARTMENT_DATA':
            return {
                ...state,
                department: state.department.filter((department: any, idx: number) => department.departmentId !== action.payload.id),
            };
        case 'FETCH_DEPARTMENT_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                department: action.payload,
            };

        //PROGRAM

        case 'ADD_PROGRAM_DATA':
            return {
                ...state,
                program: [...state.program, action.payload.data]
            }
        case 'UPDATE_PROGRAM_DATA':
            return {
                ...state,
                program: state.program.map((program: any, index: any) =>
                    index === action.payload.index ? { ...program, ...action.payload.updatedProgram } : program
                ),
            };
        case 'DELETE_PROGRAM_DATA':
            return {
                ...state,
                program: state.program.filter((program: any, idx: number) => program.programId !== action.payload.id),
            };
        case 'FETCH_PROGRAM_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                program: action.payload,
            };

        // case 'PROGRAM':
        //     return {
        //         ...state,
        //         program: action.payload.data
        //     }

        //PROGRAM TYPE
        case 'ADD_PROGRAM_TYPE_DATA':
            return {
                ...state,
                programType: [...state.programType, action.payload.data]
            }
        case 'UPDATE_PROGRAM_TYPE_DATA':
            return {
                ...state,
                programType: state.programType.map((programType: any, index: any) =>
                    index === action.payload.index ? { ...programType, ...action.payload.updateProgramType } : programType
                ),
            };
        case 'DELETE_PROGRAM_TYPE_DATA':
            return {
                ...state,
                programType: state.programType.filter((programType: any, idx: number) => programType.programTypeId !== action.payload.id),
            };
        case 'FETCH_PROGRAM_TYPE_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                programType: action.payload,
            };


        // case 'PROGRAM_TYPE':
        //     return {
        //         ...state,
        //         programType: action.payload.data
        //     }

        //PROGRAM GOAL
        case 'ADD_PROGRAM_GOAL_DATA':
            return {
                ...state,
                programGoal: [...state.programGoal, action.payload.data]
            }
        case 'UPDATE_PROGRAM_GOAL_DATA':
            return {
                ...state,
                programGoal: state.programGoal.map((programGoal: any, index: any) =>
                    index === action.payload.index ? { ...programGoal, ...action.payload.updatedProgramGoal } : programGoal
                ),
            };
        case 'DELETE_PROGRAM_GOAL_DATA':
            return {
                ...state,
                programGoal: state.programGoal.filter((programGoal: any, idx: number) => programGoal.id !== action.payload.id),
            };
        case 'FETCH_PROGRAM_GOAL_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                programGoal: action.payload,
            };
        case 'LOGOUT':
            return initialState;
        // case 'PROGRAM_GOAL':
        //     return {
        //         ...state,
        //         programGoal: action.payload.data
        //     }
        default:
            return state;
    }
};

export default tajweedReducer;