import { BASE_URL } from "../../../../../constants";
import { PORTAL_URL } from "../../../../../constants";
const readAPI = {
    // sessionType : `${BASE_URL}/EQACORE-SERVICE/schedule/sessionTypes`,
    ActiveCourses: `${BASE_URL}/EQACORE-SERVICE/activeCourses`,
    AllDataByCourseCode: `${PORTAL_URL}/portal-service/documents/data`,
    Department: `${BASE_URL}/EQACORE-SERVICE/department`,
    Campus: `${BASE_URL}/EQACORE-SERVICE/campus`,
    College: `${BASE_URL}/EQACORE-SERVICE/colleges`,
    ByLoggedinUserAndYearAndSemester: `${PORTAL_URL}/portal-service/documents/data`,
    GetAllDocumentType: `${PORTAL_URL}/portal-service/document/type/all`
  };
  
  export default readAPI;
  