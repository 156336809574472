const initialState = {
    campusName: null,
    collegeName:null,
    departmentName:null
  };
  
  const campusByLoggedinUserReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case 'CAMPUS_BY_LOGGEDIN_USER':
        return {
          ...state,
          campusName: action.payload.campusName,
          collegeName: action.payload.collegeName,
          departmentName: action.payload.departmentName,
          //data: action.payload.data,
        };
      case 'LOGOUT':
        return initialState;
      default:
        return state;
    }
  };
  
  export default campusByLoggedinUserReducer;

 

  