import axios from 'axios'
import readAPI from '../data/api/read'
import { IDocumentType, IUploadedDocumentListType } from "../model/PortalSetting"
import axiosInstance from '../../../Service/interceptor'
import createAPI from '../data/api/create'

export type DocumentType = {
    data: IDocumentType[],
    errMessage: string
}

export type DocumentListType = {
    data: IUploadedDocumentListType[],
    errMessage: ""
}

export type ResponseType = {
    success: boolean;
    errMessage: string;
    data?: any;
}



async function getAllUploadedDocumentList(): Promise<DocumentListType> {
    let url: string = readAPI.ByLoggedinUserAndYearAndSemester;
    const uploadedDocument: DocumentListType = {
        data: [],
        errMessage: ""
    };
    try {
        const res = await axiosInstance.get(url);
        uploadedDocument.data = res.data.obj;
        if (res.data.code !== "200") {
            uploadedDocument.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        uploadedDocument.errMessage = err.message;
    }
    return uploadedDocument;
}


async function getAllDocumentType(accessToken: string): Promise<DocumentType> {
    let url: string = readAPI.GetAllDocumentType;
    let header: string = accessToken;
    const documentType: DocumentType = {
        data: [],
        errMessage: ""
    }
    await axios.get(url, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            documentType.data = res.data.obj;
            if (res.data.code !== "200.200") {
                documentType.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            documentType.errMessage = err.message;
        })

    return documentType;
}

async function addUploadPortalDocument(formData: any): Promise<ResponseType> {
    let url: string = createAPI.uploadPortal
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.post(url, formData);
        if (res.data.code === "201") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}

export const PortalSettingService = {
    getAllDocumentType,
    getAllUploadedDocumentList,
    addUploadPortalDocument
}





// import axios from 'axios';
// import readAPI from '../data/api/read';
// import {ISurveyType} from "../model/Survey"
// import createAPI from '../data/api/create';
// import axiosInstance from '../../../Service/interceptor';
// import updateAPI from '../data/api/update';


// export type SurveyDetailsType = {
//     data: ISurveyType[],
//     errMessage: string
// }


// export type ResponseType = {
//     success: boolean;
//     errMessage: string;
//     data?: any;
// }

// //@function for fetching survey details

// async function getAllSurveyDetails(): Promise<SurveyDetailsType> {
//     let url: string = readAPI.getSurveyLink;
//     const surveyDetails: SurveyDetailsType = {
//         data: [],
//         errMessage: ""
//     };
//     try {
//         const res = await axiosInstance.get(url);
//         surveyDetails.data = res.data.obj;
//         if (res.data.code !== "200.200") {
//             surveyDetails.errMessage = res.data.error;
//         }
//     } catch (err: any) {
//         console.log(err);
//         surveyDetails.errMessage = err.message;
//     }
//     return surveyDetails;
// }





// async function addSurveyDetails(formData: any): Promise<ResponseType> {
//     let url: string = createAPI.postSurvey
//     const userResponse: ResponseType = {
//         success: false,
//         errMessage: "",
//         data: {}
//     }
//     try {
//         const res = await axiosInstance.post(url, formData);
//         if (res.data.code === "201") {
//             userResponse.success = true;
//             userResponse.data = res.data.obj
//         } else {
//             userResponse.errMessage = res.data.message;
//         }
//     } catch (err: any) {
//         userResponse.errMessage = err.message;
//     }
//     return userResponse;
// }

// async function updateSurveyDetails(formData: any, id: any): Promise<ResponseType> {
//     let url: string = updateAPI.updateSurvey
//     const userResponse: ResponseType = {
//         success: false,
//         errMessage: "",
//         data: {}
//     }
//     try {
//         const res = await axiosInstance.put(`${url}/${id}`, formData);
//         if (res.data.code === "200") {
//             userResponse.success = true;
//             userResponse.data = res.data.obj
//         } else {
//             userResponse.errMessage = res.data.message;
//         }
//     } catch (err: any) {
//         userResponse.errMessage = err.message;
//     }
//     return userResponse;
// }

// export const SurveyService = {
//     getAllSurveyDetails,
//     addSurveyDetails,
//     updateSurveyDetails
// }