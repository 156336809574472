export const BASE_URL_GATEWAY = 'https://69.48.179.170:8766';
export const BASE_URL = 'https://datadimens.com:8994';
export const PORTAL_URL = 'https://69.48.179.170:8766';
export const SURVEY_BASE_URL = 'https://69.48.179.170:8766/eqa-survey-service/api/v1.0/survey';
export const STUDYPLAN_URL= 'https://69.48.179.170:8766/studyplanservice/api/v1.0';
export const GDRIVE_BASE_URL = 'https://69.48.179.170:8766/eqa-gdrive-service/gdrive/v1.0';
export const Annual_Program_Report = 'https://69.48.179.170:8766/eqa-advance-report-service/api/v1.0';
export const SPS = 'https://69.48.179.170:9097/SPS'
export const UNIVERSITY_NAME = "Taif University"
export const MASTER_X_API_KEY = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJtZHNoYWJiaXJoYXNzYW4wOCIsImV4cCI6MjA0ODY2MzQxOCwiaWF0IjoxNzMzMDg3NDE4fQ.FgTzimFQHrqcHuKK5_tmvSTsCKX6MBJmjYNugcSf8k6YI7ohT6ChmDAElzNbwzeWJsKBOWLfYPVKXZ62tMulag'

 const commonConstant = {
    THEORY: 'Theory',
    LAB: 'Lab',
    PROJECT: 'Project',
    SHEET1: 'Sheet1',
    LANG_AR: 'ar',
    LANG_EN: 'en'
}
export default commonConstant;