import axios from 'axios';
import readAPI from '../data/api/read';
import {ISurveyType} from "../model/Survey"
import createAPI from '../data/api/create';
import axiosInstance from '../../../Service/interceptor';
import updateAPI from '../data/api/update';
import {MASTER_X_API_KEY } from '../../../constants';
import { ICourseCLO } from '../../eqa-core/model/EqaCore';
export type SurveyType ={
    data: ISurveyType,
    errMessage: string
}

export type SurveyDetailsType = {
    data: ISurveyType[],
    errMessage: string
}


export type ResponseType = {
    success: boolean;
    errMessage: string;
    data?: any;
}

export type CourseInfoType = {
    data: {
        courseCode: string,
        courseName: string,
        section : number,
        departmentName: string,
        programName: string,
        campusName: string,
        collegeName: string,
        semester : string,
        year: number,
        courseCLO: ICourseCLO[]
    },
    errMessage: string
}
async function getSurveyLinkDetailBySurveyId(surveyId: number):Promise<SurveyType>{
    const surveyLinkDetail: SurveyType={
        data:{} as ISurveyType,
        errMessage:""
    };

    const url = readAPI.getSurveyLinkDetail + "/"+surveyId
    try{
        // await axios.get(url, {
        //     headers: {
        //         "x-api-key": MASTER_X_API_KEY
        //     }
        // })
        await axios.get(url, {})
        .then((res) => {
            surveyLinkDetail.data = res.data.obj;
            
            if (res.data.code !== "200.200") {
                surveyLinkDetail.errMessage = res.data.error;
            }

        })
        .catch((err) => {
            surveyLinkDetail.errMessage = err.message;;
            surveyLinkDetail.data = {} as ISurveyType;
        })
    } catch (err: any) {
        console.log(err);
        surveyLinkDetail.errMessage = err.message;
    }

    return surveyLinkDetail;
}

async function getCourseInfoForCLOSurvey(courseCode: string, section: number, departmentId: string,
    programId: number, campusId: string, collegeId: string, semester: string, year: number
):Promise<CourseInfoType>{
    const courseInfoData: CourseInfoType={
        data:{
            courseCode: "",
            courseName: "",
            section : 0,
            departmentName: "",
            programName: "",
            campusName: "",
            collegeName: "",
            semester : "",
            year: 0,
            courseCLO: [] as ICourseCLO[]
        },
        errMessage:""
    };

    const url = readAPI.getCourseInfoForSurvey+"?courseCode="+courseCode+"&section="+section+"&programId="+programId+"&departmentId="+departmentId+"&campusId="+campusId+"&collegeId="+collegeId+"&semester="+semester+"&year="+year;
    try{
         await axios.get(url,{})
        .then((res) => {
            courseInfoData.data = res.data.obj;
            
            if (res.data.code !== "200.200") {
                courseInfoData.errMessage = res.data.error;
            }

        })
        .catch((err) => {
            courseInfoData.errMessage = err.message;
        })
    } catch (err: any) {
        console.log(err);
        courseInfoData.errMessage = err.message;
    }

    return courseInfoData;
}

//@function for fetching survey details
async function getAllSurveyDetails(): Promise<SurveyDetailsType> {
    let semester="1st Semester";
    let year = 2024;
    let url: string = readAPI.getSurveyLink + "?semester="+ semester + "&year=" + year;
    const surveyDetails: SurveyDetailsType = {
        data: [],
        errMessage: ""
    };
    try {
        const res = await axiosInstance.get(url);
        surveyDetails.data = res.data.obj;
        if (res.data.code !== "200.200") {
            surveyDetails.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        surveyDetails.errMessage = err.message;
    }
    return surveyDetails;
}


async function addSurveyDetails(formData: any): Promise<ResponseType> {
    let url: string = createAPI.postSurvey
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.post(url, formData);
        if (res.data.code === "201") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}

async function updateSurveyDetails(formData: any, id: any): Promise<ResponseType> {
    let url: string = updateAPI.updateSurvey
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.put(`${url}/${id}`, formData);
        if (res.data.code === "200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}


export const SurveyService = {
    getAllSurveyDetails,
    addSurveyDetails,
    updateSurveyDetails,
    getSurveyLinkDetailBySurveyId,
    getCourseInfoForCLOSurvey
}