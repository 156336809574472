interface SystemPreferencesState {
    section: any | null;
  }
  
  const initialState: SystemPreferencesState = {
    section: null,
  };

  const systemPreferencesReducer = (state = initialState, action: any): SystemPreferencesState => {
    switch (action.type) {
        case 'SECTIONS':
            return {
                ...state,
                section: action.payload.data,
            };
        case 'LOGOUT':
          return initialState;
        default:
            return state;
    }
};

export default systemPreferencesReducer;