const initialState = {
    data: null,
  };
  
  const activeAcademicYearReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case 'CACHE_ACTIVE_ACADEMIC_YEAR':
        // console.log("Action payload : "+action.payload.data);
        return {
          ...state,
          data: action.payload.data,
        };
      case 'LOGOUT':
        return initialState;
      default:
        return state;
    }
  };
  
  export default activeAcademicYearReducer;