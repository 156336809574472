import React, { useState, useEffect } from 'react';

const InternetConnectionStatus: React.FC = () => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const [isWeakConnection, setIsWeakConnection] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null); // State to control alert message
  // Detecting screen size (optional)
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  // Function to handle online and offline events
  const handleOnline = () => {
    setIsOnline(true);
    setIsWeakConnection(false); // Assume strong connection when online
    showAlert("Internet connected");
  };

  const handleOffline = () => {
    setIsOnline(false);
    setIsWeakConnection(false);
    showAlert("Internet disconnected");
  };

  // Function to show alert for a limited time (3-4 seconds)
  const showAlert = (message: string) => {
    setAlertMessage(message);
    setTimeout(() => {
      setAlertMessage(null); // Hide the alert after 3 seconds
    }, 3000); // Alert disappears after 3000 ms (3 seconds)
  };

  // Check for weak connection by pinging a server or API
  const checkWeakConnection = async () => {
    if (navigator.onLine) {
      try {
        const response = await fetch('https://www.datadimens.com', {
          method: 'GET',
            mode: 'no-cors', // no-cors to avoid response parsing
        });

        if (!response.ok) {
          setIsWeakConnection(true);
          showAlert("Weak internet connection");
        } else {
          setIsWeakConnection(false);
        }
      } catch (error) {
        setIsWeakConnection(true);
        showAlert("Weak internet connection");
      }
    }
  };


  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Periodically check for weak connection every 5 seconds
    const interval = setInterval(() => {
      checkWeakConnection();
    }, 15000);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      clearInterval(interval);
    };
  }, []);

  return (
    <div style={{
      display:'inline-flex'
    }}>
      {/* Show the alert message if present */}
      {alertMessage && (
        <div
          style={{
            padding: '10px',
            backgroundColor: isOnline ? 'rgba( 0,255, 0, 0.1)' : 'rgba(255, 0, 0, 0.1)',
            color: isOnline ? 'green' : 'red',
            fontWeight: 'bold',
            borderRadius: '5px',
            position: 'absolute', //'fixed', 'relative'
            top: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 9999,
          }}
        >
          {alertMessage}
        </div>
      )}

      
      {/* <div
        style={{
          padding: screenSize < 600 ? '3px' : '5px', // Adjust padding based on screen size
          marginRight:'10px',
          backgroundColor: isOnline ? 'rgba(0, 255, 0, 0.1)' : 'rgba(255, 0, 0, 0.1)',
          color: isOnline ? 'green' : 'red',
          fontWeight: 'bold',
          borderRadius: '5px',
          // position: 'absolute', // Fixed positioning on large screens, can change for mobile
          // top: screenSize < 600 ? '80px' : '100px', // Adjust top position on mobile
          // left: screenSize < 800 ? '80%' : screenSize < 1100 ? '85%':'91%', // Center text on mobile
          transform: screenSize < 600 ? 'translateX(-50%)' : 'none', // Center alignment for mobile
          fontSize: screenSize < 600 ? '12px' : '14px', // Font size adjustment
          transition: 'all 0.3s ease', // Smooth transition for position and size
        }}
        title="Internet Connection Status"
      >
            { isOnline ? ("Connected") : ("Disconnected")}
      </div> */}
      
      <div
          style={{
          width: '20px',          
          height: '20px',        
          backgroundColor: isOnline ? 'rgba(0, 255, 0, 0.5)' : 'rgba(255,0, 0, 0.5)', 
          borderRadius: '50%',  
          paddingRight:'10px',
          marginRight:'10px',
          // display: 'inline-block',
          // position: 'absolute', // Fixed positioning on large screens, can change for mobile
          // top: screenSize < 600 ? '80px' : '100px', // Adjust top position on mobile
          // left: screenSize < 800 ? '80%' : screenSize < 1100 ? '85%':'95%', // Center text on mobile
          boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.5)',
          transition: 'all 0.3s ease' 
        }}
        title= {isOnline ? "Internet Connected" : "Internet Disconnected"}
      ></div>
        

    </div>
  );
};

export default InternetConnectionStatus;