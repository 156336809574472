import { IScheduleCourseType, IAssessmentsByLoggedinUserType } from "../model/Assessment"
import readAPI from '../data/api/read'
import axiosInstance from '../../../Service/interceptor'
import createAPI from "../data/api/create"

export type scheduleCourseType = {
    data: IScheduleCourseType[],
    errMessage: ""
}

export type assessmentByLoggedInUserType = {
    data: IAssessmentsByLoggedinUserType[],
    errMessage: ""
}


export type ResponseType = {
    success: boolean;
    errMessage: string;
    data?: any;
}


//@ calling get api ==============
//Get all Schedule course by loggedInUser.This api get token from header and get the username. 
//Finally based on username,active, curent semester, current year, it fetchs the record.
async function getAllScheduleCourse(): Promise<scheduleCourseType> {
    let url: string = readAPI.ScheduleOfferedCourse;
    const scheduleCourses: scheduleCourseType = {
        data: [],
        errMessage: ""
    };
    try {
        const res = await axiosInstance.get(url);
        scheduleCourses.data = res.data.obj;
        if (res.data.code !== "200.200") {
            scheduleCourses.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        scheduleCourses.errMessage = err.message;
    }
    return scheduleCourses;
}

async function getAllAssessmentByLoggedInUser(): Promise<assessmentByLoggedInUserType> {
    let url: string = readAPI.Assessment;
    const assessmentByLoggedInUser: assessmentByLoggedInUserType = {
        data: [],
        errMessage: ""
    };
    try {
        const res = await axiosInstance.get(url);
        assessmentByLoggedInUser.data = res.data.obj;
        if (res.data.code !== "200.200") {
            assessmentByLoggedInUser.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        assessmentByLoggedInUser.errMessage = err.message;
    }
    return assessmentByLoggedInUser;
}


async function addAssessment(formData: any): Promise<ResponseType> {
    let url: string = createAPI.AddAssessment
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.post(url, formData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}


//@ calling update course details type api ===============================================================

async function updateAssessment(formState: any): Promise<ResponseType> {
    let url: string = createAPI.UpdateAssessment
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.put(url, formState);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}


export const AssessmentService = {
    getAllScheduleCourse,
    getAllAssessmentByLoggedInUser,
    addAssessment,
    updateAssessment
}