// reducers/userDataReducer.ts

  const initialState = {
    data: null,
  };
  
  const userDataReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case 'USER_DATA':
        // console.log("UserDataReducer > action.payload.data : " + JSON.stringify(action.payload));
        return {
          ...state,
          data: action.payload,
        };
      case 'CLEAR_USER_DATA': 
        return {
          ...state,
          data: []
        }
      case 'LOGOUT':
        return initialState;
      default:
        return state;
    }
  };
  
  export default userDataReducer;

 

  