import { PORTAL_URL } from "../../../../../constants";
const createAPI = {
    uploadPortal: `${PORTAL_URL}/portal-service/documents/upload`,
    individualFile: `${PORTAL_URL}/portal-service/documents/download`,
    individualRow: `${PORTAL_URL}/portal-service/documents/download`,
    byCourseCode: `${PORTAL_URL}/portal-service/documents/download`,
    byDepartment: `${PORTAL_URL}/portal-service/documents/download`,
    byCampus: `${PORTAL_URL}/portal-service/documents/download`,
    byCollege: `${PORTAL_URL}/portal-service/documents/download`,
    byYearAndSemester: `${PORTAL_URL}/portal-service/documents/download`,
  };
  
  export default createAPI;
  