import { BASE_URL, BASE_URL_GATEWAY,SURVEY_BASE_URL } from "../../../../../constants";
// import { SURVEY_BASE_URL } from "../../../../../constants";
const readAPI = {
    clo: `${BASE_URL}/EQACORE-SERVICE/courseCloByCourseCodeOrCloId?courseCode=`,
    getSurveyLink: "https://69.48.179.170:8766/eqa-survey-service/api/v1.0/survey/loggedInByUser",
    getSurveyData: "https://69.48.179.170:8766/eqa-survey-service/api/v1.0/survey-form-data/search?surveyId=",
    getSurveyLinkDetail: `${SURVEY_BASE_URL}`,
    getCourseInfoForSurvey:  `${BASE_URL_GATEWAY}/EQACORE-SERVICE/course/courseInfoForCLOSurvey`
  };
  
  export default readAPI;
  