import { useState, FC, lazy, Suspense } from "react";
import { Routes, Route } from 'react-router-dom';
import styles from "./style.module.css";

const Dashboard = lazy(() => import("../pages/dashboard/DashboardPage"));
const ViewManualScheduleCourse = lazy(() => import("../Components/ManualScheduleCourse/ViewManualScheduleCourse"))

interface ScheduleCourseRouteProps {
    mobileViewContainer: any,
    currentTab: any,
    setCurrentTab: any,
    // showHeader: boolean,
    setShowHeader: any,
    currentLang: string,
    setCurrentLang: any,
    openManualScheduleCourseModal: any,
    setOpenManualScheduleCourseModal: any

}

const ScheduleCourseRouter: FC<ScheduleCourseRouteProps> = ({
    currentTab,
    setCurrentTab,
    mobileViewContainer,
    // showHeader,
    setShowHeader,
    currentLang,
    setCurrentLang,
    openManualScheduleCourseModal,
    setOpenManualScheduleCourseModal

}): JSX.Element => {
    // For simple open/close sidebar
    const [isOpen, setIsOpen] = useState<Boolean>(true);
    // For minified sidebar
    const [isMinified, setIsMinified] = useState<Boolean>(false);
    const [sidebarAppsListArray, setSidebarAppsListArray] = useState<any>([]);
    return (
        <Suspense
            fallback={
                <div className={styles.loadingContainer}>
                    <div className={styles.spinner}></div>
                    <h1>Please wait...</h1>
                </div>
            }>
            <Routes>
                <Route
                    path="view"
                    element={
                        <ViewManualScheduleCourse
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}

                            openManualScheduleCourseModal={openManualScheduleCourseModal}
                            setOpenManualScheduleCourseModal={setOpenManualScheduleCourseModal}
                            setCurrentLang={setCurrentLang}

                        />
                    }
                />


                <Route path="dashboard">
                    <Route
                        path="view"
                        element={
                            <Dashboard
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


                <Route path="manualScheduleCourse">
                    <Route
                        path={"view"}
                        element={
                            <ViewManualScheduleCourse
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}

                                openManualScheduleCourseModal={openManualScheduleCourseModal}
                                setOpenManualScheduleCourseModal={setOpenManualScheduleCourseModal}
                                setCurrentLang={setCurrentLang}
                            />
                        }
                    />
                </Route>
            </Routes>
        </Suspense>
    )

}

export default ScheduleCourseRouter;
