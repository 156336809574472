

interface LoginSuccessAction {
    type: 'LOGIN_SUCCESS';
    payload: { accessToken: string };
  }
  
  interface LogoutAction {
    type: 'LOGOUT';
  }
  
  export type AuthActionTypes = LoginSuccessAction | LogoutAction;
  
  export const loginSuccess = (accessToken: string): LoginSuccessAction => ({
    type: 'LOGIN_SUCCESS',
    payload: { accessToken },
  });
  
  // export const logout = (): LogoutAction => ({
  //   type: 'LOGOUT',
  // });
  
  export const cacheActiveAcademicYearAction = (data: any) =>({
    type: 'CACHE_ACTIVE_ACADEMIC_YEAR',
    payload: data
  })

  export const userData = (data: any) => ({
    type: 'USER_DATA',
    payload: data
  })

  export const clearUserData = () => ({
    type: 'CLEAR_USER_DATA'
  });

  export const tableData = (data: any) => ({
    type: 'TABLE_DATA',
    payload: {data}
  })

  export const assignTaskData = (data: any) => ({
    type: 'ASSIGNTASK_REPORT_DATA',
    payload: {data}
  })

  export const userSignatureData = (data: any) => ({
    type: 'USER_SIGNATURE',
    payload: {data}
  })

  export const userPictureData = (data: any) => ({
    type: 'USER_PICTURE',
    payload: {data}
  });

  export const clearPicture = () => ({
    type: 'CLEAR_PICTURE'
  });



  export const campusByLoggedinUserData = (campusName?: string, departmentName?:string, collegeName?: string) => ({
    type: 'CAMPUS_BY_LOGGEDIN_USER',
    payload: {
      campusName,
      departmentName,
      collegeName
    }
  });

  export const programListData = (data: any) => ({
    type: 'PROGRAM_DATA',
    payload: {data}
  })
  export const collegeListData = (data: any) => ({
    type: 'COLLEGE_DATA',
    payload: {data}
  })
  export const campusListData = (data: any) => ({
    type: 'CAMPUS_DATA',
    payload: {data}
  })
  export const departmentListData = (data: any) => ({
    type: 'DEPARTMENT_DATA',
    payload: {data}
  })
  export const sessionTypeListData = (data: any) => ({
    type: 'SESSION_TYPE_DATA',
    payload: {data}
  })

  export const assessmentTypeListData = (data: any) => ({
    type: 'ASSESSMENT_TYPE_DATA',
    payload: {data}
  })

export const offeredCourseListData = (data: any) => ({
    type: 'OFFERED_COURSE_DATA',
    payload: {data}
  })

  export const courseData = (data: any) => ({
    type: 'COURSE_DATA',
    payload: {data}
  })

  export const userListData = (data: any) => ({
    type: 'USER_LIST_DATA',
    payload: {data}
  })

  export const driveFileListData = (data: any) => ({
    type: 'DRIVE_FILELIST_DATA',
    payload: {data}
  })

  export const driveDirectoryListData = (data: any) => ({
    type: 'DRIVE_DIRECTORYLIST_DATA',
    payload: {data}
  })

