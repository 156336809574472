// reducers/authReducer.ts

interface AuthState {
    isAuthenticated: boolean;
    accessToken: string | null;
  }
  
  const initialState: AuthState = {
    isAuthenticated: false,
    accessToken: null,
  };
  
  const authReducer = (state = initialState, action: any): AuthState => {
    switch (action.type) {
      case 'LOGIN_SUCCESS':
        return {
          ...state,
          isAuthenticated: true,
          accessToken: action.payload.accessToken,
        };
      // case 'LOGOUT':
      //   return {
      //     ...state,
      //     isAuthenticated: false,
      //     accessToken: null,
      //   };
      case 'LOGOUT':
        return initialState;
      default:
        return state;
    }
  };
  
  export default authReducer;
  