const initialState = {
    data: null,
  };
  

const tableDataReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case 'TABLE_DATA':
        return {
          ...state,
          data: action.payload.data,
        };
      case 'LOGOUT':
        return initialState;
      default:
        return state;
    }
  };

export default tableDataReducer;