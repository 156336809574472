


//uploadedDocumentList

import { ThunkAction } from 'redux-thunk';
import { AnyAction, Dispatch } from 'redux';
import { RootState } from '../store';
import { SurveyService } from '../../Apps/survey/service/SurveyService';
import { PortalSettingService } from '../../Apps/portal/service/PortalSettingService';


export const GET_UPLOADED_DOCUMENT = 'GET_UPLOADED_DOCUMENT';
export const GET_UPLOADED_DOCUMENT_TYPE = 'GET_UPLOADED_DOCUMENT_TYPE';

export const FETCH_UPLOADED_DOCUMENT_LIST_REQUEST = 'FETCH_UPLOADED_DOCUMENT_LIST_REQUEST';
export const FETCH_UPLOADED_DOCUMENT_LIST_SUCCESS = 'FETCH_UPLOADED_DOCUMENT_LIST_SUCCESS';
export const FETCH_UPLOADED_DOCUMENT_LIST_FAILURE = 'FETCH_UPLOADED_DOCUMENT_LIST_FAILURE';
export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';


  //@ function for survey data ============================================================

export const fetchUploadedDocumentRedux = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (dispatch: Dispatch): Promise<void> => {
      dispatch({ type: FETCH_UPLOADED_DOCUMENT_LIST_REQUEST });
      try {
          const response = await PortalSettingService.getAllUploadedDocumentList();
          dispatch({ type: FETCH_UPLOADED_DOCUMENT_LIST_SUCCESS, payload: response.data });
      } catch (error: any) {
          dispatch({ type: FETCH_UPLOADED_DOCUMENT_LIST_FAILURE, payload: error.message });
      }
  };
}

export const adddDocumentRedux = (data: any) => ({
  type: UPLOAD_DOCUMENT,
  payload: { data }
});

export const updatedDocumentRedux = (updatedDocument: any, id: any) => ({
  type: UPDATE_DOCUMENT,
  payload: { updatedDocument, id },
});

export const deletedDocumentRedux = (id: number ) => ({
  type: DELETE_DOCUMENT,
  payload: { id }
});


// export const uploadedDocumentList = (data: any) => ({
//     type: GET_UPLOADED_DOCUMENT,
//     payload: {data}
//   })

  export const uploadedDocumentTypeList = (data: any) => ({
    type: GET_UPLOADED_DOCUMENT_TYPE,
    payload: {data}
  })
