interface AccountState {
    manageUser: any | null;
    manageGroup: any | null;
    manageUserGroup: any | null;
    manageRole: any | null;
    manageGroupRole: any | null;
    manageApplication: any | null;
    manageAppForm: any | null;
    manageRoleApp: any | null;
    loading: boolean;
    data: any | null;
    error: '';
}

const initialState: AccountState = {
    manageUser: null,
    manageGroup: null,
    manageUserGroup: null,
    manageRole: null,
    manageGroupRole: null,
    manageApplication: null,
    manageAppForm: null,
    manageRoleApp: null,
    loading: false,
    data: null,
    error: '',
};

const accountReducer = (state = initialState, action: any): AccountState => {
    switch (action.type) {
        case 'ADD_USER':
            return {
                ...state,
                data: [...state.data, action.payload.data]
            }
        case 'UPDATE_USER':
            return {
                ...state,
                data: state.data.map((user: any, index: any) =>
                    index === action.payload.index ? { ...user, ...action.payload.updatedUser } : user
                ),
            };
        case 'DELETE_USER':
            return {
                ...state,
                data: state.data.filter((user: any, index: any) => index !== action.payload.index),
            };
        case 'FETCH_USER_DATA_REQUEST':
            return {
                ...state,
                loading: true,
            };
        case 'FETCH_USER_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case 'FETCH_USER_DATA_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case 'ADD_GROUP':
            return {
                ...state,
                manageGroup: [...state.manageGroup, action.payload.data]
            }
        case 'UPDATE_GROUP':
            return {
                ...state,
                manageGroup: state.manageGroup.map((group: any, index: any) =>
                    index === action.payload.index ? { ...group, ...action.payload.updatedGroup } : group
                ),
            };
        case 'DELETE_GROUP':
            return {
                ...state,
                manageGroup: state.manageGroup.filter((user: any, index: any) => user.grpId !== action.payload.id),
            };
        case 'FETCH_GROUP_DATA_REQUEST':
            return {
                ...state,
                loading: true,
            };
        case 'FETCH_GROUP_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                manageGroup: action.payload,
            };
        case 'FETCH_GROUP_DATA_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        //user group

        case 'ADD_USER_GROUP':
            return {
                ...state,
                manageUserGroup: [...state.manageUserGroup, action.payload.data]
            }
        case 'UPDATE_USER_GROUP':
            return {
                ...state,
                manageUserGroup: state.manageUserGroup.map((userGroup: any, index: any) =>
                    index === action.payload.index ? { ...userGroup, ...action.payload.updatedUserGroup } : userGroup
                ),
            };
        case 'DELETE_USER_GROUP':
            return {
                ...state,
                manageUserGroup: state.manageUserGroup.filter((user: any, index: any) => user.userGroupId !== action.payload.id),
            };
        case 'FETCH_USER_GROUP_DATA_REQUEST':
            return {
                ...state,
                loading: true,
            };
        case 'FETCH_USER_GROUP_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                manageUserGroup: action.payload,
            };
        case 'FETCH_USER_GROUP_DATA_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        //ROLE
        case 'ADD_ROLE':
            return {
                ...state,
                manageRole: [...state.manageRole, action.payload.data]
            }
        case 'UPDATE_ROLE':
            return {
                ...state,
                manageRole: state.manageRole.map((role: any, index: any) =>
                    index === action.payload.index ? { ...role, ...action.payload.updatedRole } : role
                ),
            };
        case 'DELETE_ROLE':
            return {
                ...state,
                manageRole: state.manageRole.filter((user: any, index: any) => user.roleId !== action.payload.id),
            };
        case 'FETCH_ROLE_DATA_REQUEST':
            return {
                ...state,
                loading: true,
            };
        case 'FETCH_ROLE_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                manageRole: action.payload,
            };
        case 'FETCH_ROLE_DATA_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        //GROUP ROLE
        case 'ADD_GROUP_ROLE':
            return {
                ...state,
                manageGroupRole: [...state.manageGroupRole, action.payload.data]
            }
        case 'UPDATE_GROUP_ROLE':
            return {
                ...state,
                manageGroupRole: state.manageGroupRole.map((groupRole: any, index: any) =>
                    index === action.payload.index ? { ...groupRole, ...action.payload.updatedGroupRole } : groupRole
                ),
            };
        case 'DELETE_GROUP_ROLE':
            return {
                ...state,
                manageGroupRole: state.manageGroupRole.filter((user: any, index: any) => user.groupRoleId !== action.payload.id),
            };
        case 'FETCH_GROUP_ROLE_DATA_REQUEST':
            return {
                ...state,
                loading: true,
            };
        case 'FETCH_GROUP_ROLE_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                manageGroupRole: action.payload,
            };
        case 'FETCH_GROUP_ROLE_DATA_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        //APPLICATION

        case 'ADD_APPLICATION':
            return {
                ...state,
                manageApplication: [...state.manageApplication, action.payload.data]
            }
        case 'UPDATE_APPLICATION':
            return {
                ...state,
                manageApplication: state.manageApplication.map((application: any, index: any) =>
                    index === action.payload.index ? { ...application, ...action.payload.updatedApplication } : application
                ),
            };

            case 'DELETE_APPLICATION':
            return {
                ...state,
                manageApplication: state.manageApplication.filter((user: any, index: any) => user.appId !== action.payload.id),
            };

        case 'FETCH_APPLICATION_DATA_REQUEST':
            return {
                ...state,
                loading: true,
            };
        case 'FETCH_APPLICATION_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                manageApplication: action.payload,
            };
        case 'FETCH_APPLICATIOIN_DATA_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        //APP FORM
        case 'ADD_APP_FORM':
            return {
                ...state,
                manageAppForm: [...state.manageAppForm, action.payload.data]
            }
        case 'UPDATE_APP_FORM':
            return {
                ...state,
                manageAppForm: state.manageAppForm.map((appForm: any, index: any) =>
                    index === action.payload.index ? { ...appForm, ...action.payload.updatedAppForm } : appForm
                ),
            };
            case 'DELETE_APP_FORM':
            return {
                ...state,
                manageAppForm: state.manageAppForm.filter((user: any, index: any) => user.formId !== action.payload.id),
            };

        case 'FETCH_APPFORM_DATA_REQUEST':
            return {
                ...state,
                loading: true,
            };
        case 'FETCH_APPFORM_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                manageAppForm: action.payload,
            };
        case 'FETCH_APPFORM_DATA_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        //ROLE APP PRIVILEGE
        case 'ADD_ROLE_APP_PRIVILEGE':
            return {
                ...state,
                manageRoleApp: [...state.manageRoleApp, action.payload.data]
            }
        case 'UPDATE_ROLE_APP_PRIVILEGE':
            return {
                ...state,
                manageRoleApp: state.manageRoleApp.map((roleApp: any, index: any) =>
                    index === action.payload.index ? { ...roleApp, ...action.payload.updatedRoleAppPrivilege } : roleApp
                ),
            };
            case 'DELETE_ROLE_APP_PRIVILEGE':
            return {
                ...state,
                manageRoleApp: state.manageRoleApp.filter((user: any, index: any) => user.privilegeId !== action.payload.id),
            };
        case 'FETCH_ROLE_APP_PRIVILEGE_DATA_REQUEST':
            return {
                ...state,
                loading: true,
            };
        case 'FETCH_ROLE_APP_PRIVILEGE_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                manageRoleApp: action.payload,
            };
        case 'FETCH_ROLE_APP_PRIVILEGE_DATA_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case 'MANAGE_USER':
            return {
                ...state,
                manageUser: action.payload.data,
            };
        case 'MANAGE_GROUP':
            return {
                ...state,
                manageGroup: action.payload.data,
            };
        case 'MANAGE_USER_GROUP':
            return {
                ...state,
                manageUserGroup: action.payload.data,
            };
        case 'APPEND_USER_GROUP':
            return {
                ...state,
                manageUserGroup: state.manageUserGroup
                    ? [...state.manageUserGroup, action.payload.data] // Append new data
                    : [action.payload.data] // Initialize as array if null
            };
        case 'MANAGE_ROLE':
            return {
                ...state,
                manageRole: action.payload.data,
            };
        case 'MANAGE_GROUP_ROLE':
            return {
                ...state,
                manageGroupRole: action.payload.data,
            };
        case 'MANAGE_APPFORM':
            return {
                ...state,
                manageApplication: action.payload.data,
            };
        case 'MANAGE_APP_FORM':
            return {
                ...state,
                manageAppForm: action.payload.data,
            };
        case 'MANAGE_ROLE_APP_PRIVILAGE':
            return {
                ...state,
                manageRoleApp: action.payload.data,
            };
        case 'LOGOUT':
            return initialState;
       default:
            return state;
    }
};

export default accountReducer;