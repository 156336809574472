import React, { FC, useCallback, useMemo, useState, useEffect, memo } from 'react';
import Avatar from '@mui/material/Avatar';
import Cookies from 'js-cookie';
import axios from 'axios';

import { useNavigate, useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';

import "./loader.css";
import LockResetIcon from '@mui/icons-material/LockReset';
import SnackBar from '../../SnackBar';
import readAPI from '../../../Apps/survey/data/api/read';
import deleteSPS from '../../../Apps/sps/data/api/delete'


import MaterialReactTable, {
    // MaterialReactTableProps,
    MRT_Cell,
    MRT_ColumnDef,
    MRT_Row,
} from 'material-react-table';

import {
    Box,
    Table,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    TextField,
    Tooltip,
    Grid,
    Typography,
    Alert,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';

import { Delete, Download, Edit, ErrorOutline } from '@mui/icons-material';

// Importing Edit Table Modal
import EditTableModal from '../EditTableModal';

// Importing API urls
// Delete API
import deleteAPI from '../../../Data/API/DELETE';
import deleteAPIS from '../../../Apps/eqa-core/data/api/delete';
import deleteAPIScheduleCourse from '../../../Apps/scheduleCourse/data/api/delete';
// Update API
import updateAPI from '../../../Data/API/UPDATE';
import updateAPIS from '../../../Apps/eqa-core/data/api/update';
import updateAPIScheduleCourse from "../../../Apps/scheduleCourse/data/api/update"

// importing modular css
import styles from './style.module.css';

// Importing the simple css
import './style.css';
import { EncryptConfig } from '../../../Security/EncryptConfig';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { BsEyeFill } from 'react-icons/bs';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import Syllabus from '../../../Apps/eqa-core/Components/Course/Syllabus';
import { Annual_Program_Report, BASE_URL, STUDYPLAN_URL } from '../../../constants';
import { CampusDetails, CollegeDetails, DepartmentDetails, GlobalService, ProgramDetails } from '../../../Service/GlobalService';
import { useDispatch } from 'react-redux';
import { assignTaskData } from '../../../redux/action/authActions';
import { alertDeleteConfirm, alertError, alertSuccess } from '../../../Apps/studyPlan/Components/helpers/alerts';
import { styled } from '@mui/material';
import { removeGoal, removeInitiative, removeKpi, removeKpiValue, removeStrategicPlanByIndex } from '../../../redux/action/spsActions';
import ViewStrategicPlanModal from '../Modals/ViewStrategicPlanModal';
import ViewInitiativeModal from '../Modals/ViewInitiativeModal';
import ViewGoalModal from '../Modals/ViewGoalModal';
import ViewKpisModal from '../Modals/ViewKpisModal';
import ViewKpisValueModal from '../Modals/ViewKpisValueModal';
import { deleteCampusRedux, deleteCollegeRedux, deleteDepartmentRedux, deleteProgramGoalRedux, deleteProgramRedux, deleteProgramTypeRedux, deleteUniversityRedux } from '../../../redux/action/tajweedCoreActions';
import { deleteCommitteeDataRedux } from '../../../redux/action/committeeActions';
import { deleteAssessmentDetailsRedux } from '../../../redux/action/assessmentActions';
import { deleteAppFormRedux, deleteApplicationRedux, deleteGroupRedux, deleteGroupRoleRedux, deleteRoleAppPrivilegeRedux, deleteRoleRedux, deleteUserGroupRedux } from '../../../redux/action/accountActions';
import { deleteCourseDetailsRedux } from '../../../redux/action/manageCourseActions';


interface CustomTableProps {
    data: any;
    states: string[];
    columnName: string;
    buttonTitle: string;
    isOpen: Boolean;
    currentLang: string;
    colHeader: any;
    setColHeader: any;
    setFetchUpdate: any;
    setForUpdate: any;
    setEnableEditing?: boolean;
    studyPlanCompleteData?: any;
    assignTaskCompleteData?: any;
    loadingStatus?: any;
    deletedFun?: any

}

const CustomTableCrud: FC<CustomTableProps> = ({
    data,
    states,
    columnName,
    buttonTitle,
    isOpen,
    currentLang,
    colHeader,
    setColHeader,
    setFetchUpdate,
    setForUpdate,
    setEnableEditing = true,
    studyPlanCompleteData,
    assignTaskCompleteData,
    loadingStatus,
    deletedFun
}): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();

    const [openModal, setOpenModal] = useState(false);
    const [openSurveyModal, setOpenSurveyModal] = useState(false);
    const [openViewAssessmentModal, setOpenViewAssessmentModal] = useState(false);
    const [openViewStudyPlanModal, setOpenViewStudyPlanModal] = useState(false);
    const [openViewPasswordChange, setOpenViewPasswordChange] = useState(false);
    const [openViewGoalModal, setOpenViewGoalModal] = useState(false);
    const [fetchUpdateRefresh, setFetchUpdateRefresh] = useState(false);

    const [originalValues, setOriginalValues] = useState<any>(null);
    const [currentRowIndex, setCurrentRowIndex] = useState<number>(-1);
    const [studyPlanOfferedProgram, setStudyPlanOfferedProgram] = useState<any>([]);
    const [goalDatas, setGoalDatas] = useState<any>([]);
    const [goalMappingToKpi, setGoalMappingToKpi] = useState<any>([]);
    const [assignTaskTasks, setAssignTaskTasks] = useState<any>([]);
    const [goalData, setGoalData] = useState<any>();

    const [openViewStrategicPlanModal, setOpenViewStrageticPlanModal] = useState(false);
    const [strategicPlanData, setStrategicPlanData] = useState();

    const [openViewInitiativeModal, setOpenViewInitiativeModal] = useState(false);
    const [initiativeData, setInitiativeData] = useState();

    const [openViewKpisModal, setOpenViewKpisModal] = useState(false);
    const [kpisData, setKpisData] = useState<any>();

    const [openViewKpisValueModal, setOpenViewKpisValueModal] = useState(false);
    const [kpisValueData, setKpisValueData] = useState<any>();

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');

    ///////////////////////////////// Snackbar State /////////////////////////////////
    const [snackBarHandler, setSnackBarHandler] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    ///////////////////////////////// Snackbar State /////////////////////////////////


    const truncateText = (text: any, maxLength = 100) => {
        if (text?.length > maxLength) {
            return text?.substring(0, maxLength) + '...';
        }
        return text;
    };

    // const [enableEditing, setEnableEditing] = useState<boolean>(false);

    // const [columnStateValues, setColumnStateValues] = useState<any>(null);

    // const [createModalOpen, setCreateModalOpen] = useState(false);

    // const [tableData, setTableData] = useState<any>(() => data);
    const [validationErrors, setValidationErrors] = useState<{
        [cellId: string]: string;
    }>({});

    // useEffect(() => {
    //     console.log("data", data);
    //     console.log("studyPlanCompleteData>>>>>>>>>", studyPlanCompleteData);
    // }, [data, studyPlanCompleteData]);




    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    // For Checking the Editable and Deletable Permission
    let FinalsidebarAppsListArray = JSON.parse(localStorage.getItem("sidebarAppsListArray") || '[]');

    const [editable, setEditable] = useState<boolean>(false);
    const [deletable, setDeletable] = useState<boolean>(false);

    const [URL, setURL] = useState<string>("");

    useEffect(() => {
        // For getting the current location path
        const currentLocationPath = location.pathname;

        // 

        // setting edit and delete permission
        for (let i = 0; i < FinalsidebarAppsListArray.length; i++) {
            let subMenu = FinalsidebarAppsListArray[i].subMenu;
            for (let j = 0; j < subMenu.length; j++) {
                // alert("Current Location Path is: " + currentLocationPath + " and the subMenu[j].formUrl is: " + subMenu[j].formUrl + " and the i is: " + i + " and the Menu URL is: " + FinalsidebarAppsListArray[i].appUrl);
                if (subMenu[j].formUrl === currentLocationPath || FinalsidebarAppsListArray[i].appUrl === currentLocationPath) {
                    // alert("Equal" + FinalsidebarAppsListArray[i].appName)
                    if (subMenu[j].updatePermission === true) {
                        setEditable(true);
                        // console.log("Is Editable ===> ", editable);
                    }
                    if (subMenu[j].deletePermission === true) {
                        setDeletable(true);
                        // console.log("Is Deletable ===> ", deletable);
                    }
                }
            }
        }
    }, [FinalsidebarAppsListArray, deletable, editable, location.pathname]);
    // For Checking the Editable and Deletable Permission

    const getCommonEditTextFieldProps = useCallback(
        (
            cell: MRT_Cell<any>,
        ): MRT_ColumnDef<any>['muiTableBodyCellEditTextFieldProps'] => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'email'
                            ? validateEmail(event.target.value)
                            : cell.column.id === 'age'
                                ? validateAge(+event.target.value)
                                : validateRequired(event.target.value);
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    

    const [tableColumnsNew, setTableColumnsNew] = useState<MRT_ColumnDef<any>[]>([]);

    const generateColumns = useCallback((data: any, currentLang: string) => {
        // console.log("Data from CustomTableCrud component shabbir: ", data);
        //console.log("Data Length from CustomTableCrud component shabbir: ", data.length);
        // console.log("Data colHeader from customTAbleCrud component shabbir: ", colHeader);
        // console.log("Data object is array ? ", Array.isArray(data));

        if (!data || !data.length) return [];

        const keyOrder = colHeader.flat();

        const keys = Object.keys(data[0]).sort((a, b) => {
            const indexA = keyOrder.indexOf(a);
            const indexB = keyOrder.indexOf(b);
            return indexA - indexB;
        });

        let columnNamesArabic: any = null;

        if (columnName === "ViewUsers") {
            columnNamesArabic = {
                "createdBy": 'تم الإنشاء بواسطة',
                "creationDateAndTime": 'تاريخ ووقت الإنشاء',
                "updatedBy": 'تم التحديث بواسطة',
                "updateDateAndTime": 'تاريخ ووقت التحديث',
                "userId": 'معرّف المستخدم',
                "firstName": 'الاسم الأول',
                "lastName": 'اسم العائلة',
                "userName": 'اسم المستخدم',
                "password": 'كلمة السر',
                "emailId": 'البريد الإلكتروني',
                "collegeId": 'معرّف الكلية',
                "campusId": 'معرّف الحرم الجامعي',
                "departmentId": 'معرّف القسم',
                "emailStatus": 'حالة البريد الإلكتروني',
                "staff": 'الموظفين',
                "superUser": 'المستخدم الفائق',
                "active": 'نشط'
            };
        }else if (columnName === "ViewApps") {
            columnNamesArabic = {
                "createdBy": "تم الإنشاء بواسطة",
                "creationDateAndTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDateAndTime": "تاريخ ووقت التحديث",
                "appId": "معرّف التطبيق",
                "appName": "اسم التطبيق",
                "appName_Ar": "اسم التطبيق بالعربية",
                "appIcon": "رمز التطبيق",
                "appDescription": "وصف التطبيق",
                "appUrl": "رابط التطبيق",
                "appOrder": "ترتيب التطبيق",
                "active": "نشط"
            }
        }else if (columnName === "ViewGroups") {
            columnNamesArabic = {
                "createdBy": "المُنشئ",
                "creationDateAndTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "المُحدّث",
                "updateDateAndTime": "تاريخ ووقت التحديث",
                "grpId": "معرّف المجموعة",
                "grpName": "اسم المجموعة",
                "grpDescription": "وصف المجموعة",
                "active": "نشط"
            };
        }else if (columnName === "ViewRoles") {
            columnNamesArabic = {
                "createdBy": 'المنشئ',
                "creationDateAndTime": 'تاريخ الإنشاء والوقت',
                "updatedBy": 'المحدث',
                "updateDateAndTime": 'تاريخ التحديث والوقت',
                "roleId": 'رقم الدور',
                "roleName": 'اسم الدور',
                "roleDescription": 'وصف الدور',
                "active": 'نشط'
            };
        }else if (columnName === "ViewAppForm") {
            columnNamesArabic = {
                "createdBy": "صُنِّعَ بواسطة",
                "creationDateAndTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "تحديث بواسطة",
                "updateDateAndTime": "تاريخ ووقت التحديث",
                "formId": "معرّف النموذج",
                "moduleName": "اسم الوحدة النمطية",
                "formName": "اسم النموذج",
                "formName_Ar": "اسم النموذج بالعربية",
                "formIcon": "رمز النموذج",
                "formUrl": "عنوان URL للنموذج",
                "appDetails": "وصف التطبيق",
                "active": "نشط؟",
                "appId": "معرّف التطبيق"
            }
        }else if (columnName === "ViewRoleApp") {
            columnNamesArabic = {
                "createdBy": "المنشئ",
                "creationDateAndTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "المحدث",
                "updateDateAndTime": "تاريخ ووقت التحديث",
                "privilegeId": "معرّف الصلاحية",
                "role": "الدور",
                "appDetails": "تفاصيل التطبيق",
                "appForms": "أشكال التطبيق",
                "createPermission": "صلاحية الإنشاء",
                "readPermission": "صلاحية القراءة",
                "updatePermission": "صلاحية التحديث",
                "deletePermission": "صلاحية الحذف",
                "active": "نشط؟"
            }
        } else if (columnName === "ViewGroupRole") {
            columnNamesArabic = {
                "createdBy": "صُنِّعَ بواسطة",
                "creationDateAndTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "تحديث بواسطة",
                "updateDateAndTime": "تاريخ ووقت التحديث",
                "groupRoleId": "معرف دور المجموعة",
                "groupDetails": "تفاصيل المجموعة",
                "role": "الدور",
                "grpRoleDescription": "وصف دور المجموعة",
                "grpId": "معرّف المجموعة",
                "roleId": "معرّف الدور",
                "active": "نشط؟"
            }
        } else if (columnName === "ViewUserGroup") {
            columnNamesArabic = {
                "createdBy": "تم إنشاؤه بواسطة",
                "creationDateAndTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDateAndTime": "تاريخ ووقت التحديث",
                "userGroupId": "معرّف مجموعة المستخدمين",
                "user": "المستخدم",
                "group": "المجموعة",
                "active": "نشط",
                "userId": "معرف المستخدم",
                "grpId": "معرف المجموعة"
            }
        } else if (columnName === "ViewCollege") {
            columnNamesArabic = {
                "createdBy": "تم إنشاؤه بواسطة",
                "creationDateTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDateTime": "تاريخ ووقت التحديث",
                "collegeId": "كلية",
                "collegeName": "اسم الكلية",
                "collegeEmailId": "معرف البريد الإلكتروني للكلية",
                "collegeWebAddress": "المجموعة",
                "addressLine1": "العنوان سطر 1",
                "addressLine2": "سطر العنوان 2",
                "addressLine3": "العنوان",
                "zipCode": "الرمز البريدي",
                "province": "مقاطعة",
                "country": "دولة",
                "collegePhoneNumber": "الكلية",
                "collegeFaxNumber": "الكليةفاكسرقم",
                "collegeMission": "الكلية",
                "collegeVision": "كلية الرؤية",
                "active": "نشيط",
            }
        }else if (columnName === "ViewUniversity") {
            columnNamesArabic = {
                "createdBy": "تم إنشاؤه بواسطة",
                "creationDateTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDateTime": "تاريخ ووقت التحديث",
                "universityId": "جامعة",
                "universityName": "اسم الجامعة",
                "universityEmailId": "الجامعة",
                "universityWebAddress": "عنوان الجامعة",
                "addressLine1": "العنوان سطر 1",
                "addressLine2": "سطر العنوان 2",
                "addressLine3": "العنوان",
                "zipCode": "الرمز البريدي",
                "province": "مقاطعة",
                "country": "دولة",
                "universityPhoneNumber": "رقم الهاتف بالجامعة",
                "universityFaxId": "جامعةفاكس رقم",
                "universityMission": "الجامعة",
                "universityVision": "رؤية الجامعة",
                "active": "نشيط",
            }
        }else if (columnName === "ViewCampus") {
            columnNamesArabic = {
                "createdBy": "تم إنشاؤه بواسطة",
                "creationDateTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDateTime": "تاريخ ووقت التحديث",
                "campusId": "الحرم الجامعي",
                "campusName": "الاسم",
                "campusEmailId": "معرف البريد الإلكتروني في الحرم الجامعي",
                "campusWebAddress": "عنوان الجامعة",
                "addressLine1": "العنوان سطر 1",
                "addressLine2": "سطر العنوان 2",
                "addressLine3": "العنوان",
                "zipCode": "الرمز البريدي",
                "province": "مقاطعة",
                "country": "دولة",
                "campusPhoneNumber": "رقم الهاتف",
                "campusFaxNumber": "رقم فاكس الحرم الجامعي",
                "campusMission": "الحرم الجامعي",
                "campusVision": "رؤية الحرم الجامعي",
                "active": "نشيط",
            }
        }else if (columnName === "ViewDepartment") {
            columnNamesArabic = {
                "createdBy": "تم إنشاؤه بواسطة",
                "creationDateTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDateTime": "تاريخ ووقت التحديث",
                "departmentId": "معرف القسم",
                "departmentName": "اسم القسم",
                "departmentEmailId": "القسم",
                "departmentWebAddress": "عنوان موقع القسم",
                "addressLine1": "العنوان سطر 1",
                "addressLine2": "سطر العنوان 2",
                "addressLine3": "العنوان",
                "departmentChair": "رئيس القسم",
                "departmentPhoneNumber": "رقم الهاتف",
                "departmentFaxNumber": "القسمالفاكسعدد",
                "departmentMission": "القسم",
                "departmentVision": "قسم الرؤية",
                "active": "نشط",
            }
        }else if (columnName === "ViewProgram") {
            columnNamesArabic = {
                "createdBy": "تم إنشاؤه بواسطة",
                "creationDateTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDateTime": "تاريخ ووقت التحديث",
                "programApprovalDate": "تاريخ الموافقة البرنامج",
                "programDesc": "برنامج",
                "programDuration": "مدة البرنامج",
                "programId": "معرف البرنامج",
                "programMission": "البرنامج",
                "programStartDate": "تاريخ بدء البرنامج",
                "programTitle": "عنوان البرنامج",
                "programTotalCredits": "إجمالي الرصيد الدراسي للبرنامج",
                "programVision": "رؤية البرنامج",
                "programWebAddress": "عنوان موقع البرنامج",
                "active": "نشط",
            }
        } else if (columnName === "ViewProgramType") {
            columnNamesArabic = {
                "createdBy": "تم إنشاؤه بواسطة",
                "creationDateTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDateTime": "تاريخ ووقت التحديث",
                "programDesc": "برنامج",
                "programTypeId": "نوع البرنامج",
                "programName": "عنوان البرنامج",
                "programRemarks": "رؤية البرنامج",
                "active": "نشط",
            }
        } else if (columnName === "ViewProgramObjective") {
            columnNamesArabic = {
                "createdBy": "تم إنشاؤه بواسطة",
                "creationDateTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDateTime": "تاريخ ووقت التحديث",
                "soDescription": "برنامج",
                "soId": "عنوان البرنامج",
                "remarks": "رؤية البرنامج",
                "programId": "رؤية البرنامج",
                "id": "رؤية",
                "active": "نشط",
            }
        }else if (columnName === "ViewCourseDetails") {
            columnNamesArabic = {
                "academicYearOffered": "السنة الأكاديمية المقدمة",
                "alhChLaboratory": "المختبر",
                "alhChLecture": "محاضرة",
                "alhChOther": "محاضرة",
                "alhChTutorial": "alhCh تعليمي",
                "alhOlhAssignment": "alhOlh التنازل",
                "alhOlhLibrary": "مكتبة الحلة",
                "alhOlhOther": "alhOlh أخرى",
                "alhOlhResearch": "alhOlh Research",
                "alhOlhStudy": "دراسة alhOlh",
                "courseCoRequisite": "بالطبع",
                "courseCode": "كود الدورة",
                "courseCreditHours": "الدورات الائتمانية",
                "courseDesc": "بالطبع",
                "courseLevel": "المستوى",
                "courseName": "اسم الدورة التدريبية",
                "coursePrerequisite": "بالطبع شرط أساسي",
                "courseTypeName": "نوع الدورة",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "frAccomodation": "الإقامة",
                "frOtherResources": "fr مصادر أخرى",
                "frTechnologyResources": "مصادر التكنولوجيا",
                "labHoursPerWeek": "ساعات العمل في الأسبوع",
                "moiBlended": "موي المخلوطة",
                "moiCorrespondence": "مراسلات وزارة الداخلية",
                "moiElearning": "moi التعلم الإلكتروني",
                "moiOthers": "آخرون",
                "moiTraditionalClassroom": "الفصول الدراسية التقليدية moi",
                "requiredElective": "مطلوب",
                "sadCommittee": "اللجنة الحزينة",
                "sadDate": "التاريخ",
                "sadReferenceNo": "حزين المرجع",
                "studentAcademicSupport": "الطالب الأكاديميالدعم",
                "studyPlan": "خطة دراسية",
                "teachingHoursPerWeek": "التدريس في الأسبوع",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط",
            }
        }else if (columnName === "ViewCourseType") {
            columnNamesArabic = {
                "courseTypeDesc": "نوع الدورة",
                "courseTypeName": "نوع الدورة",
                "courseTypeRemarks": "نوع الدورة",
                "id": "بطاقة تعريف",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط",
            }
        }else if (columnName === "ViewCourseObjective") {
            columnNamesArabic = {
                "courseCode": "كود الدورة",
                "courseObjectiveId": "الهدف المقرر",
                "description": "وصف",
                "id": "بطاقة تعريف",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        } else if (columnName === "ViewCourseLearningOutcome") {
            columnNamesArabic = {
                "assessmentMethod": "طريقة التقييم",
                "cloCategory": "فئة clo",
                "cloId": "معرف clo",
                "courseCode": "كود الدورة",
                "description": "وصف",
                "teachingStrategies": "استراتيجيات التدريس",
                "id": "بطاقة تعريف",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }else if (columnName === "ViewTopics") {
            columnNamesArabic = {
                "closCovered": "مغلق",
                "courseCode": "كود الدورة",
                "courseTopicId": "بالطبع",
                "topic": "عنوان",
                "weeksDelivered": "تم التسليم",
                "id": "بطاقة تعريف",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }else if (columnName === "ViewCourseBook") {
            columnNamesArabic = {
                "courseCode": "كود الدورة",
                "electronicMaterials": "المواد الإلكترونية",
                "otherLearningMaterials": "مواد تعليمية أخرى",
                "otherReferenceBook": "كتاب مرجعي آخر",
                "referenceBook": "كتاب مرجعي",
                "referenceMaterials": "مرجعالمواد",
                "textBook": "كتاب مدرسي",
                "id": "بطاقة تعريف",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        } else if (columnName === "ViewBook") {
            columnNamesArabic = {
                "author": "مؤلف",
                "description": "وصف",
                "edition": "الإصدار",
                "editor": "محرر",
                "isbn10": "isbn10",
                "isbn13": "isbn13",
                "name": "اسم",
                "publisher": "الناشر",
                "websiteUrl": "رابط الموقع",
                "yearOfPublication": "سنة النشر",
                "id": "بطاقة تعريف",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }else if (columnName === "ViewAssessments") {
            columnNamesArabic = {
                "assessmentDate": "تاريخ التقييم",
                "assessmentId": "معرف التقييم",
                "assessmentTime": "وقت التقييم",
                "assessmentTypeId": "التقييم",
                "createdBy": "انشأ من قبل",
                "creationDateAndTime": "تاريخ الإنشاء والوقت",
                "maximumMarks": "علامات قصوى",
                "noOfQuestions": "لا من الأسئلة",
                "offeredCourseId": "معروض كورس",
                "projectDescription": "وصف المشروع",
                "projectTitle": "عنوان المشروع",
                "courseCode": "رمز الدورة",
                "section": "قسم",
                "assessmentName": "اسم التقييم",
                "supervisorName": "اسم المشرف",
                "examiner1Name": "اسم الممتحن1",
                "examiner2Name": "اسم الممتحن 2",
                "sessionType": "نوع الجلسة",
                "collegeName": "اسم الكلية",
                "departmentName": "اسم القسم",
                "active": "نشيط"
            }
        }else if (columnName === "ViewAssessmentType") {
            columnNamesArabic = {
                "assessmentTypeId": "التقييم",
                "assessmentTypeName": "نوع التقييم",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "description": "وصف",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }else if (columnName === "ViewStrategicPlan") {
            columnNamesArabic = {
                "strategicPlanName": "اسم الخطة الاستراتيجية",
                "description": "وصف",
                "startDate": "تاريخ البدء",
                "endDate": "تاريخ الانتهاء",
                "manager": "مدير",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }else if (columnName === "ViewInitiative") {
            columnNamesArabic = {
                "initiativeName": "اسم المبادرة",
                "manager": "مدير",
                "executiveManager": "المدير التنفيذي",
                "startDate": "تاريخ البدء",
                "endDate": "تاريخ الانتهاء",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }else if (columnName === "ViewKPI") {
            columnNamesArabic = {
                "kpiName": "اسم مؤشر الأداء الرئيسي",
                "description": "وصف",
                "degree": "درجة",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }else if (columnName === "ViewKPIValue") {
            columnNamesArabic = {
                "program": "برنامج",
                "kpiName": "اسم مؤشر الأداء الرئيسي",
                "target": "درجة",
                "benchmark": "المعيار",
                "newtarget": "هدف جديد",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }else if (columnName === "ViewGoalData") {
            columnNamesArabic = {
                "goalName": "اسم الهدف",
                "goalDescription": "وصف الهدف",
                "goalManager": "مدير الأهداف",
                "initiative": "مبادرة",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }else if (columnName === "ViewManualScheduleCourse") {
            columnNamesArabic = {
                "courseCode": "رمز الدورة",
                "collegeId": "معرف الكلية",
                "campusId": "معرف الحرم الجامعي",
                "departmentId": "معرف القسم",
                "instructorId": "معرف المدرب",
                "noOfStudent": "لا من الطالب",
                "offeredCourseId": "معرف الدورة المقدمة",
                "semester": "نصف السنة",
                "academicYear": "السنة الأكاديمية",
                "section": "قسم",
                "sessionType": "نوع الجلسة",
                "sessionName": "اسم الجلسة",
                "departmentName": "اسم القسم",
                "campusName": "اسم الحرم الجامعي",
                "collegeName": "اسم الكلية",
                "courseName": "اسم الدورة التدريبية",
                "programName": "إسم البرنامج",
                "instructorName": "اسم المدرب",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }else if (columnName === "ViewSessionTypeData") {
            columnNamesArabic = {
                "name": "اسم",
                "description": "وصف",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }else if (columnName === "ViewAcademicYearData") {
            columnNamesArabic = {
                "year": "سنة",
                "semester": "نصف السنة",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }else if (columnName === "ViewCourseCoordinatorData") {
            columnNamesArabic = {
                "courseCode": "رمز الدورة",
                "coordinatorId": "معرف المنسق",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }else if (columnName === "ViewOfferedCourses") {
            columnNamesArabic = {
                "offeredCourseId": "معرف الدورة المقدمة",
                "section": "قسم",
                "noOfStudent": "لا من الطالب",
                "sessionType": "نوع الجلسة",
                "instructorId": "معرف المدرب",
                "semester": "نصف السنة",
                "academicYear": "السنة الأكاديمية",
                "collegeName": "اسم الكلية",
                "campusName": "اسم الحرم الجامعي",
                "departmentName": "اسم القسم",
                "courseCode": "رمز الدورة",
                "sessionName": "اسم الجلسة",
                "emailId": "عنوان الايميل",
                "programName": "إسم البرنامج",
                "instructorName": "اسم المدرب",
                "year": "سنة",
                "courseName": "اسم الدورة التدريبية",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }else if (columnName === "ViewGoalData") {
            columnNamesArabic = {
                "goalName": "اسم المدرب",
                "goalDescription": "سنة",
                "goalManager": "اسم الدورة التدريبية",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }

        }else if (columnName === "CourseOfferingTypes") {
            columnNamesArabic = {
                "courseCode": "رمز الدورة",
                "courseName": "اسم الدورة التدريبية",
                "section": "قسم",
                "sessionName": "اسم الجلسة",
                "instructorName": "اسم المدرب",
                "emailId": "عنوان الايميل",
                "programName": "إسم البرنامج",
                "departmentName": "اسم القسم",
                "campusName": "اسم الحرم الجامعي",
                "collegeName": "اسم الكلية",
                "semester": "نصف السنة",
                "year": "سنة",
                "active": "نشيط"
            }
        } else if (columnName === "ViewProjectTemplate") {
            columnNamesArabic = {
                "subComponentName": "اسم المكون الفرعي",
                "clos": "مغلق",
                "maxMarks": "ماكس ماركس",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }else if (columnName === "ViewCoordinator") {
            columnNamesArabic = {
                "courseCode": "رمز الدورة",
                "coordinatorId": "معرف المنسق",
                "createdBy": "تم الإنشاء بواسطة",
                "creationDateTime": "تاريخ ووقت الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }else if (columnName === "ViewSurvey") {
            columnNamesArabic = {
                "courseCode": "رمز الدورة",
                "sectionNumber": "رقم المقطع",
                "url": "عنوان",
                "noOfDays": "لا أيام",
                "semester": "نصف السنة",
                "year": "سنة",
                "collegeId": "معرف الكلية",
                "campusId": "معرف الحرم الجامعي",
                "departmentId": "معرف القسم",
                "closCount": "كلوسكونت",
                "studentCount": "عدد الطلاب",
                "conductedSurveyCount": "أجرى عدد المسح",
                "createdBy": "تم الإنشاء بواسطة",
                "creationDateAndTime": "تاريخ ووقت الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }else if (columnName === "ViewUploadDocStatus") {
            columnNamesArabic = {
                "title": "عنوان",
                "sessionType": "نوع الجلسة",
                "required": "مطلوب",
                "collegeId": "معرف الكلية",
                "createdBy": "انشأ من قبل",
                "creationDate": "تاريخ الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updationDate": "تاريخ التحديث",
                "displayOrder": "عرض الطلب",
                "active": "نشيط"
            }
        }else if (columnName === "ViewCreatedStudyPlan") {
            columnNamesArabic = {
                "title": "عنوان",
                "programId": "معرف البرنامج",
                "approvalDate": "تاريخ الموافقة",
                "creditHours": "ساعات الاعتماد",
                "createdBy": "انشأ من قبل",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }else if (columnName === "ViewCommittee") {
            columnNamesArabic = {
                "committeeName": "اسم اللجنة",
                "committeeDescription": "وصف اللجنة",
                "committeeChair": "كرسي اللجنة",
                "department": "قسم",
                "campus": "حرم الجامعة",
                "college": "كلية",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDateTime": "تحديث التاريخ والوقت",
                "active": "نشيط"
            }
        }else if (columnName === "ViewCommitteeMember") {
            columnNamesArabic = {
                "memberId": "معرف العضو",
                "committeeId": "معرف اللجنة",
                "memberList": "قائمة الأعضاء",
                "createdBy": "انشأ من قبل",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        } else if (columnName === "ViewAnnualProgramReport") {
            columnNamesArabic = {
                "id": "",
                "sectionName": "اسم القسم",
                "description": "وصف",
                "orderValue": "ثمن الطلب",
                "active": "نشيط",
                "academicYear": "السنة الأكاديمية",
                "createdBy": "انشأ من قبل",
                "creationDatetime": "تاريخ الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDatetime": "تحديث التاريخ والوقت"
            }
        }else if (columnName === "ViewAssignTaskReport") {
            columnNamesArabic = {
                "reportId": "معرف التقرير",
                "programName": "إسم البرنامج",
                "departmentName": "اسم القسم",
                "collegeName": "اسم الكلية",
                "academicYear": "السنة الأكاديمية",
                "responsible": "مسؤول",
                "sectionName": "اسم القسم",
                "active": "نشيط",
                "createdBy": "انشأ من قبل",
                "creationDatetime": "تاريخ الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDatetime": "تحديث التاريخ والوقت",
                "actions": "أجراءات"
            }
        }

        const generate = keys.map((key) => {
            let header = key
                .toString()
                .replace(/([a-z])([A-Z])/g, '$1 $2') // add space between camelCase words
                .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // add space between consecutive capital letters
                .replace(/^./, (str) => str.toUpperCase()); // capitalize first letter

            if (currentLang === 'ar' && columnName !== "CourseOfferingTypes") {
                // Map column name to Arabic if current language is Arabic
                header = columnNamesArabic[key] || header;
            }



            return {
                accessorKey: key.toString(),
                header,
                size: (key.toString() === 'appDetails' || key.toString() === 'formUrl') ? 280 : 280,
                muiTableBodyCellEditTextFieldProps: ({ cell }: any) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            };
        });
        //console.log("generate value : " + JSON.stringify(generate))
        setTableColumnsNew(generate);

        // Extract the column names from the data
        // const NewKeys = Object.keys(generate.header);

        let NewKeys: any = [];

        for (let i = 0; i < generate.length; i++) {
            NewKeys.push(generate[i].header);
        }
        // console.log("NewKeys ===> ", [NewKeys]);

        setColHeader([NewKeys]);
        //console.log("Header : " + NewKeys)
        return generate;
    }, [columnName, getCommonEditTextFieldProps]);

    // // Usage:
    const columnsNew = useMemo(() => generateColumns(data, currentLang), [currentLang, data, generateColumns]);
    //console.log("columnsNew : " + JSON.stringify(columnsNew));
    // State to Store rows 
    const [tableData, setTableData] = useState<any>(null);

    // Function to generate rows
    const generateRows = useCallback((data: any) => {
        if (!data || !data.length) return [];

        // Sort the data in descending order such that creationDateAndTime latest entry is shown first
        // Do this only if the data has creationDateAndTime property
        if (data[0].hasOwnProperty('creationDateAndTime')) {
            data.sort((a: any, b: any) => {
                return b.creationDateAndTime[0] - a.creationDateAndTime[0] ||
                    b.creationDateAndTime[1] - a.creationDateAndTime[1] ||
                    b.creationDateAndTime[2] - a.creationDateAndTime[2] ||
                    b.creationDateAndTime[3] - a.creationDateAndTime[3] ||
                    b.creationDateAndTime[4] - a.creationDateAndTime[4] ||
                    b.creationDateAndTime[5] - a.creationDateAndTime[5];
            });
        }

        if (data[0].hasOwnProperty('creationDateTime')) {

            const comparingDateForSorting = (a: any, b: any) => {
                const dateA: any = new Date(a.creationDateTime);
                const dateB: any = new Date(b.creationDateTime);
                return dateB - dateA;
            };

            // Sort the data array in descending order based on "creationDateTime"
            data.sort(comparingDateForSorting);
        }


        let BigRow = [];

        for (let i = 0; i < data.length; i++) {
            // @ts-ignore
            let newRow: any = {};
            for (const prop in data[i]) {
                switch (prop) {
                    case 'creationDateAndTime':
                        if (currentLang === 'ar') {
                            // Date in Arabic
                            newRow[prop] = new Date(
                                data[i][prop][0],
                                data[i][prop][1] - 1,
                                data[i][prop][2],
                                data[i][prop][3],
                                data[i][prop][4],
                                data[i][prop][5]
                            ).toLocaleString('ar-EG');
                        }
                        else {
                            // Date in English
                            newRow[prop] = new Date(
                                data[i][prop][0],
                                data[i][prop][1] - 1,
                                data[i][prop][2],
                                data[i][prop][3],
                                data[i][prop][4],
                                data[i][prop][5]
                            ).toLocaleString();

                        }
                        break;
                    case 'updateDateAndTime':
                        if (data[i][prop] !== null) {
                            // console.log('updateDateAndTime', data[i][prop]);
                            if (currentLang === 'ar') {
                                // Date in Arabic
                                newRow[prop] = new Date(
                                    data[i][prop][0],
                                    data[i][prop][1] - 1,
                                    data[i][prop][2],
                                    data[i][prop][3],
                                    data[i][prop][4],
                                    data[i][prop][5]
                                ).toLocaleString('ar-EG');
                            }
                            else {
                                // Date in English
                                newRow[prop] = new Date(
                                    data[i][prop][0],
                                    data[i][prop][1] - 1,
                                    data[i][prop][2],
                                    data[i][prop][3],
                                    data[i][prop][4],
                                    data[i][prop][5]
                                ).toLocaleString();

                            }
                        }
                        else {
                            newRow[prop] = '';
                            // console.log('updateDateAndTime Null --> ', data[i][prop]);
                        }
                        break;
                    case 'appDetails':
                        //newRow[prop] = data[i][prop] ? 'true' : 'false';
                        // newRow[prop] = data[i][prop]."appName";
                        newRow[prop] = data[i][prop].appId + ": " + data[i][prop].appName;
                        // newRow['appId'] = data[i][prop].appId;
                        break;
                    case 'active':
                        // console.log("Active : ", data[i][prop]);
                        newRow[prop] = data[i][prop].toString();
                        break;
                    case 'staff':
                        newRow[prop] = data[i][prop].toString();
                        break;
                    case 'superUser':
                        newRow[prop] = data[i][prop].toString();
                        break;
                    default:
                        newRow[prop] = data[i][prop] || '';
                }
            }

            // Push the new row to the big row
            BigRow.push(newRow);
        }

        setTableData(BigRow);

        // console.log("Generate Big Row ===> ", BigRow);
        return BigRow;
    }, [currentLang]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const rowsNew = useMemo(() => generateRows(data), [data, generateRows]);


    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);

    const loggedInUser = useSelector((state: RootState) => state.userData.data);
    const strategicPlanDetail = useSelector((state: RootState) => state.sps.strategicPlanDetailed);

    const [strategicPlanEndDate, setStrategicPlanEndDate] = useState(strategicPlanDetail?.[0]?.data?.strategicPlans?.endDate || [])
    const [getInitiative, setGetInitiative] = useState(
        strategicPlanDetail?.[0]?.data?.initiatives || []
        // strategicPlanDetail[0]?.data?.strategicPlans?.initiativeDetailDtoList
    );

    const handleDeleteRow = useCallback(
        (row: MRT_Row<any>) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            let colName = columnsNew[0].accessorKey;
            //send api delete request here, then refetch or update local table data for re-render

            // let accessToken: any = Cookies.get("accessToken");

            if (accessToken === undefined || accessToken === null) {
                accessToken = null;
            }

            if (accessToken !== null) {
                console.log("Column Name ===> ", columnName);

                let url = "";
                let message = "";
                let deleteMessage = "";

                if (columnName === "ViewUsers") {
                    const userId = row.getValue('userId');
                    const userName = row.getValue('userName');

                    // Get the user id from the row values
                    // console.log("User ID ===> ", userId);
                    // console.log("User Name ===> ", userName);

                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPI.Users}${userId}`;
                    message = `Are you sure you want to delete user: ${userName} ?`;
                    deleteMessage = `User: ${userName} deleted successfully`;
                }else if (columnName === "ViewApps") {
                    const appId = row.getValue('appId');
                    const appName = row.getValue('appName');

                    // Get the user id from the row values
                    // console.log("User ID ===> ", appId);
                    // console.log("User Name ===> ", appName);

                    url = `${deleteAPI.Apps}${appId}`;
                    message = "Are you sure you want to delete App " + appName + " ?";
                    deleteMessage = `App ${appName} Deleted Successfully`;
                    dispatch(deleteApplicationRedux(row.getValue('appId')));
                }else if (columnName === "ViewGroups") {
                    const grpId = row.getValue('grpId');
                    const grpName = row.getValue('grpName');

                    // Get the user id from the row values
                    // console.log("Group ID ===> ", grpId);
                    // console.log("Group Name ===> ", grpName);

                    url = `${deleteAPI.Groups}${grpId}`;
                    message = "Are you sure you want to delete Group " + grpName + " ?";
                    deleteMessage = `Group ${grpName} Deleted Successfully`;
                    dispatch(deleteGroupRedux(row.getValue('grpId')))
                }else if (columnName === "ViewRoles") {
                    const roleId = row.getValue('roleId');
                    const roleName = row.getValue('roleName');

                    // Get the user id from the row values
                    // console.log("Role ID ===> ", roleId);
                    // console.log("Role Name ===> ", roleName);

                    url = `${deleteAPI.Roles}${roleId}`;
                    message = "Are you sure you want to delete Group " + roleName + " ?";
                    deleteMessage = `Group ${roleName} Deleted Successfully`;
                    dispatch(deleteRoleRedux(row.getValue('roleId')));
                }else if (columnName === "ViewAppForm") {
                    const formId = row.getValue('formId');
                    const formName = row.getValue('formName');

                    // Get the user id from the row values
                    // console.log("form ID ===> ", formId);
                    // console.log("form Name ===> ", formName);

                    url = `${deleteAPI.AppForm}${formId}`;
                    message = "Are you sure you want to delete AppForm " + formName + " ?";
                    deleteMessage = `AppForm ${formName} Deleted Successfully`;
                    dispatch(deleteAppFormRedux(row.getValue('formId')));
                }else if (columnName === "ViewRoleApp") {
                    const privilegeId = row.getValue('privilegeId');

                    url = `${deleteAPI.RoleApp}${privilegeId}`;
                    message = "Are you sure you want to delete AppRole Privilege " + privilegeId + " ?";
                    deleteMessage = `AppRole Privilege with Id: ${privilegeId} Deleted Successfully`;
                    dispatch(deleteRoleAppPrivilegeRedux(row.getValue('privilegeId')));
                }else if (columnName === "ViewGroupRole") {
                    const groupRoleId = row.getValue('groupRoleId');

                    url = `${deleteAPI.GroupRole}${groupRoleId}`;
                    message = "Are you sure you want to delete GroupRole " + groupRoleId + " ?";
                    deleteMessage = `GroupRole with Id: ${groupRoleId} Deleted Successfully`;
                    dispatch(deleteGroupRoleRedux(row.getValue('groupRoleId')));
                }else if (columnName === "ViewUserGroup") {
                    const userGroupId = row.getValue('userGroupId');

                    url = `${deleteAPI.UserGroup}${userGroupId}`;
                    message = "Are you sure you want to delete UserGroup " + userGroupId + " ?";
                    deleteMessage = `UserGroup with Id: ${userGroupId} Deleted Successfully`;
                    dispatch(deleteUserGroupRedux(row.getValue('userGroupId')));
                }else if (columnName === "ViewCollege") {
                    const collegeId: any = row.getValue('collegeId');
                    const collegeName = row.getValue('collegeName');

                    // Get the user id from the row values
                    console.log("User ID ===> ", collegeId);
                    // console.log("User Name ===> ", userName);

                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteCollege}${collegeId}`;
                    message = `Are you sure you want to delete college: ${collegeName} ?`;
                    deleteMessage = `college: ${collegeName} deleted successfully`;
                    dispatch(deleteCollegeRedux(row.getValue('collegeId')));
                }else if (columnName === "ViewUniversity") {
                    const universityId = row.getValue('universityId');
                    const universityName = row.getValue('universityName');

                    // Get the user id from the row values
                    // console.log("User ID ===> ", universityId);
                    // console.log("User Name ===> ", userName);

                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteUniversity}${universityId}`;
                    message = `Are you sure you want to delete university: ${universityName} ?`;
                    deleteMessage = `University: ${universityName} deleted successfully`;
                    dispatch(deleteUniversityRedux(row.getValue('universityId')));
                }else if (columnName === "ViewCampus") {
                    const campusId = row.getValue('campusId');
                    const campusName = row.getValue('campusName');

                    // Get the user id from the row values
                    // console.log("User ID ===> ", campusId);
                    // console.log("User Name ===> ", userName);

                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteCampus}${campusId}`;
                    message = `Are you sure you want to delete campus: ${campusName} ?`;
                    deleteMessage = `Campus: ${campusName} deleted successfully`;
                    dispatch(deleteCampusRedux(row.getValue('campusId')));
                }else if (columnName === "ViewDepartment") {
                    const departmentId = row.getValue('departmentId');
                    const departmentName = row.getValue('departmentName');

                    // Get the user id from the row values
                    // console.log("User ID ===> ", departmentId);
                    // console.log("User Name ===> ", userName);

                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteDepartment}${departmentId}`;
                    message = `Are you sure you want to delete department: ${departmentName} ?`;
                    deleteMessage = `Department: ${departmentName} deleted successfully`;
                    dispatch(deleteDepartmentRedux(row.getValue('departmentId')));
                }else if (columnName === "ViewProgram") {
                    const programId = row.getValue('programId');
                    const programTitle = row.getValue('programTitle');

                    // Get the user id from the row values
                    // console.log("User ID ===> ", programId);
                    // console.log("User Name ===> ", userName);

                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteProgram}${programId}`;
                    message = `Are you sure you want to delete program: ${programTitle} ?`;
                    deleteMessage = `Program: ${programTitle} deleted successfully`;
                    dispatch(deleteProgramRedux(row.getValue('programId')));
                }else if (columnName === "ViewProgramType") {
                    const programTypeId = row.getValue('programTypeId');
                    const programName = row.getValue('programName');

                    // Get the user id from the row values
                    // console.log("User ID ===> ", programTypeId);
                    // console.log("User Name ===> ", userName);

                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteProgramType}${programTypeId}`;
                    message = `Are you sure you want to delete program: ${programName} ?`;
                    deleteMessage = `Program Type: ${programName} deleted successfully`;
                    dispatch(deleteProgramTypeRedux(row.getValue('programTypeId')));
                }else if (columnName === "ViewProgramObjective") {
                    const id = row.getValue('id');
                    const soId = row.getValue('soId');

                    // Get the user id from the row values
                    // console.log("User ID ===> ", id);
                    // console.log("User Name ===> ", userName);

                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteObjective}${id}`;
                    message = `Are you sure you want to delete program: ${soId} ?`;
                    deleteMessage = `SO Id: ${soId} deleted successfully`;
                    dispatch(deleteProgramGoalRedux(row.getValue('id')));
                }else if (columnName === "ViewCourseDetails") {
                    const id = row.getValue('courseCode');
                    const courseName = row.getValue('courseName');
                    // Get the user id from the row values
                    // console.log("User ID ===> ", id);
                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteCourse}${id}`;
                    message = `Are you sure you want to delete program: ${courseName} ?`;
                    deleteMessage = `Course Name: ${courseName} deleted successfully`;
                    dispatch(deleteCourseDetailsRedux(row.getValue('courseCode')));
                }else if (columnName === "ViewCourseType") {
                    const id = row.getValue('id');
                    const courseTypeName = row.getValue('courseTypeName');
                    // Get the user id from the row values
                    // console.log("User ID ===> ", id);
                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteCourseType}${id}`;
                    message = `Are you sure you want to delete program: ${courseTypeName} ?`;
                    deleteMessage = `Course Name: ${courseTypeName} deleted successfully`;
                }else if (columnName === "ViewCourseObjective") {
                    const id = row.getValue('id');
                    const courseObjective = row.getValue('courseObjective');
                    // Get the user id from the row values
                    // console.log("User ID ===> ", id);
                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteCourseObjective}${id}`;
                    message = `Are you sure you want to delete program: ${courseObjective} ?`;
                    deleteMessage = `Course Objective: ${courseObjective} deleted successfully`;
                }else if (columnName === "ViewCourseLearningOutcome") {
                    const id = row.getValue('id');
                    const cloId = row.getValue('cloId');
                    // Get the user id from the row values
                    // console.log("User ID ===> ", id);
                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteCLO}${id}`;
                    message = `Are you sure you want to delete program: ${cloId} ?`;
                    deleteMessage = `CLO: ${cloId} deleted successfully`;
                }else if (columnName === "ViewTopics") {
                    const id = row.getValue('id');
                    const courseTopicId = row.getValue('courseTopicId');
                    // Get the user id from the row values
                    // console.log("User ID ===> ", id);
                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteTopic}${id}`;
                    message = `Are you sure you want to delete program: ${courseTopicId} ?`;
                    deleteMessage = `Course Topic Id: ${courseTopicId} deleted successfully`;
                }else if (columnName === "ViewCourseBook") {
                    const id = row.getValue('id');
                    const textBook = row.getValue('otherReferenceBook');
                    // Get the user id from the row values
                    // console.log("User ID ===> ", id);
                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteCourseBook}${id}`;
                    message = `Are you sure you want to delete program: ${textBook} ?`;
                    deleteMessage = `Course Book: ${textBook} deleted successfully`;
                }else if (columnName === "ViewBook") {
                    const id = row.getValue('id');
                    const name = row.getValue('name');
                    // Get the user id from the row values
                    // console.log("User ID ===> ", id);
                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteBook}${id}`;
                    message = `Are you sure you want to delete program: ${name} ?`;
                    deleteMessage = `Book Name: ${name} deleted successfully`;
                }else if (columnName === "ViewCloSoMapping") {
                    const id = row.getValue('id');
                    const cloId = row.getValue('cloId');
                    // Get the user id from the row values
                    // console.log("User ID ===> ", id);
                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteCLOSOMapping}${id}`;
                    message = `Are you sure you want to delete program: ${cloId} ?`;
                    deleteMessage = `CLO: ${cloId} deleted successfully`;
                }else if (columnName === "ViewAssessments") {
                    const id = row.getValue('assessmentId');
                    const projectTitle = row.getValue('assessmentName');
                    // Get the user id from the row values
                    // console.log("User ID ===> ", id);
                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteAssessment}${id}`;
                    message = `Are you sure you want to delete assessment: ${projectTitle} ?`;
                    deleteMessage = `Assessment deleted successfully`;
                    dispatch(deleteAssessmentDetailsRedux(row.getValue('assessmentId')))
                }else if (columnName === "ViewAssessmentType") {
                    const id = row.getValue('assessmentTypeId');
                    const assessmentTypeName = row.getValue('assessmentTypeName');
                    // Get the user id from the row values
                    // console.log("User ID ===> ", id);
                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteAssessmentType}${id}`;
                    message = `Are you sure you want to delete program: ${assessmentTypeName} ?`;
                    deleteMessage = `Assessment Type: ${assessmentTypeName} deleted successfully`;
                }else if (columnName === "ViewStrategicPlan") {
                    const id = row.getValue('assessmentTypeId');
                    const assessmentTypeName = row.getValue('assessmentTypeName');
                    // Get the user id from the row values
                    // console.log("User ID ===> ", id);
                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteAssessmentType}${id}`;
                    message = `Are you sure you want to delete program: ${assessmentTypeName} ?`;
                    deleteMessage = `Assessment Type: ${assessmentTypeName} deleted successfully`;
                }else if (columnName === "ViewInitiative") {
                    const id = row.getValue('assessmentTypeId');
                    const assessmentTypeName = row.getValue('assessmentTypeName');
                    // Get the user id from the row values
                    // console.log("User ID ===> ", id);
                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteAssessmentType}${id}`;
                    message = `Are you sure you want to delete program: ${assessmentTypeName} ?`;
                    deleteMessage = `Assessment Type: ${assessmentTypeName} deleted successfully`;
                }else if (columnName === "ViewKPI") {
                    const id = row.getValue('assessmentTypeId');
                    const assessmentTypeName = row.getValue('assessmentTypeName');
                    // Get the user id from the row values
                    // console.log("User ID ===> ", id);
                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteAssessmentType}${id}`;
                    message = `Are you sure you want to delete program: ${assessmentTypeName} ?`;
                    deleteMessage = `Assessment Type: ${assessmentTypeName} deleted successfully`;
                }else if (columnName === "ViewKPIValue") {
                    const id = row.getValue('assessmentTypeId');
                    const assessmentTypeName = row.getValue('assessmentTypeName');
                    // Get the user id from the row values
                    // console.log("User ID ===> ", id);
                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteAssessmentType}${id}`;
                    message = `Are you sure you want to delete program: ${assessmentTypeName} ?`;
                    deleteMessage = `Assessment Type: ${assessmentTypeName} deleted successfully`;
                }else if (columnName === "ViewGoalData") {
                    const id = row.getValue('assessmentTypeId');
                    const assessmentTypeName = row.getValue('assessmentTypeName');
                    // Get the user id from the row values
                    // console.log("User ID ===> ", id);
                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIS.DeleteAssessmentType}${id}`;
                    message = `Are you sure you want to delete program: ${assessmentTypeName} ?`;
                    deleteMessage = `Assessment Type: ${assessmentTypeName} deleted successfully`;
                }else if (columnName === "ViewManualScheduleCourse") {
                    const id = row.getValue('offeredCourseId');
                    const scheduleCourseName = row.getValue('offeredCourseId');
                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIScheduleCourse.DeleteOfferedCourse}${id}`;
                    message = `Are you sure you want to delete offeredCourse: ${scheduleCourseName} ?`;
                    deleteMessage = `OfferedCourse: ${scheduleCourseName} deleted successfully`;
                }else if (columnName === "ViewSessionTypeData") {
                    const id = row.getValue('id');
                    const sessionTypeName = row.getValue('name');
                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIScheduleCourse.DeleteSessionType}${id}`;
                    message = `Are you sure you want to delete session type: ${sessionTypeName} ?`;
                    deleteMessage = `Session: ${sessionTypeName} deleted successfully`;
                }else if (columnName === "ViewAcademicYearData") {
                    const id = row.getValue('id');
                    const academicYear = row.getValue('year');
                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIScheduleCourse.DeleteAcademicYear}${id}`;
                    message = `Are you sure you want to delete academic Year: ${academicYear} ?`;
                    deleteMessage = `Academic Year: ${academicYear} deleted successfully`;
                }else if (columnName === "ViewCourseCoordinatorData") {
                    const id = row.getValue('id');
                    const courseCoordinator = row.getValue('coordinatorId');
                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    url = `${deleteAPIScheduleCourse.DeleteCourseCoordinator}${id}`;
                    message = `Are you sure you want to delete Course Coordinator: ${courseCoordinator} ?`;
                    deleteMessage = `Course Co-ordinator: ${courseCoordinator} deleted successfully`;
                }else if (columnName === "ViewProjectTemplate") {
                    const id = row.getValue('subComponentId');
                    const subComponentName = row.getValue('subComponentName');
                    url = `${deleteAPIS.DeleteProjectSubComponent}${id}`;
                    message = `Are you sure you want to delete Sub Component: ${subComponentName} ?`;
                    deleteMessage = `Sub Component: ${subComponentName} deleted successfully`;
                }else if (columnName === "ViewCoordinator") {
                    const id = row.getValue('id');
                    const coordinatorId = row.getValue('coordinatorId');
                    url = `${deleteAPIS.DeleteCoordinator}${id}`;
                    message = `Are you sure you want to delete Coordinator: ${coordinatorId} ?`;
                    deleteMessage = `Coordinator: ${coordinatorId} deleted successfully`;
                }else if (columnName === "ViewSurvey") {
                    const id = row.getValue('id');
                    const surveyId = row.getValue('id');
                    url = `${deleteAPIS.DeleteSurveyLinkDetails}${id}`;
                    message = `Are you sure you want to delete SurveyID: ${surveyId} ?`;
                    deleteMessage = `Survey: ${surveyId} deleted successfully`;
                }else if (columnName === "ViewUploadDocStatus") {
                    const id = row.getValue('id');
                    const documentTypeId = row.getValue('id');
                    url = `${deleteAPIS.DeleteDocumentType}${id}`;
                    message = `Are you sure you want to delete Document: ${documentTypeId} ?`;
                    deleteMessage = `Document Type: ${documentTypeId} deleted successfully`;
                }else if (columnName === "ViewCreatedStudyPlan") {
                    const id = row.getValue('id');
                    const studyPlanId = row.getValue('id');
                    url = `${deleteAPIS.DeleteStudyPlan}${id}`;
                    message = `Are you sure you want to delete study plan: ${studyPlanId} ?`;
                    deleteMessage = `Study Plan: ${studyPlanId} deleted successfully`;
                }else if (columnName === "ViewCommittee") {
                    const id = row.getValue('id');
                    const committeeId = row.getValue('id');
                    url = `${deleteAPIS.DeleteCommittee}${id}`;
                    message = `Are you sure you want to delete Committe: ${committeeId} ?`;
                    deleteMessage = `Committee: ${committeeId} deleted successfully`;
                    dispatch(deleteCommitteeDataRedux(row.getValue('id')))
                }else if (columnName === "ViewCommitteeMember") {
                    const id = row.getValue('memberId');
                    const memberId = row.getValue('memberId');
                    url = `${deleteAPIS.DeleteCommittee}${id}`;
                    message = `Are you sure you want to delete Committee Member: ${memberId} ?`;
                    deleteMessage = `Committee Member: ${memberId} deleted successfully`;
                }else if (columnName === "ViewLocation") {
                    const id = row.getValue('id');
                    const locationId = row.getValue('id');
                    url = `${deleteAPIS.DeleteLocation}${id}`;
                    message = `Are you sure you want to delete Location: ${locationId} ?`;
                    deleteMessage = `Location: ${locationId} deleted successfully`;
                }else if (columnName === "ViewAnnualProgramReport") {
                    const id = row.getValue('id');
                    const reportId = row.getValue('id');
                    url = `${deleteAPIS.DeleteAnnualProgramReport}${id}`;
                    message = `Are you sure you want to delete Report: ${reportId} ?`;
                    deleteMessage = `Annual Program Report: ${reportId} deleted successfully`;
                }else if (columnName === "ViewAssignTaskReport") {
                    const id = row.getValue('id');
                    const reportId = row.getValue('id');
                    url = `${deleteAPIS.DeleteAnnualProgramReport}${id}`;
                    message = `Are you sure you want to delete task: ${reportId} ?`;
                    deleteMessage = `Task: ${reportId} deleted successfully`;
                }else {
                    alert("Wrong Column Name");
                    console.warn("Wrong Column Name");
                    // tableData.splice(row.index, 1);
                    // setTableData([...tableData]);
                }

                if (url !== "" && message !== "" && deleteMessage !== "") {
                    if (
                        // eslint-disable-next-line no-restricted-globals
                        !confirm(`${message}`)
                    ) {
                        return;
                    }

                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                    axios.delete(`${url}`, {
                        headers: {
                            'x-api-key': accessToken,
                            'Accept-Language': 'en'
                        }
                    })
                        .then(res => {
                            // console.log(`Delete ${columnName} Response ===> `, res.data);
                            if (res.data.status === "OK") {
                                tableData.splice(row.index, 1);
                                setTableData([...tableData]);
                                alert(deleteMessage);
                                deletedFun(true);
                            }
                        })
                        .catch(err => {
                            console.log(`Error Deleting ${columnName} ===> `, err);
                        });
                    // Send a DELETE request to delete the row in the server also pass the header of access token as x-access-token
                }
            }
            else {
                alert("Please login first");
                navigate('/login');
            }
        },
        [columnName, columnsNew, navigate, tableData],
    );

    
    let userLocalStorage: any = useSelector((state: RootState) => state.userData.data);
    const storedData = localStorage.getItem('courseData');
    // const offeredCourseData = storedData ? JSON.parse(storedData) : [];
    const offeredCourseData = storedData && storedData !== 'undefined' ? JSON.parse(storedData) : [];

   

    const [cloIdss, setCloIdss] = useState<any>();
    const [surveyData, setSurveyData] = useState<any>();
    const [isSurveyDataError, setIsSurveyDataError] = useState<any>(false);
    const [studyPlanData, setStudyPlanData] = useState<any>();
    const [filteredDetails, setFilteredDetails] = useState<any>();

    //To check internet connection
    const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
    // Function to handle online and offline events
    const handleOnline = () => {
        setIsOnline(true);
     };
    const handleOffline = () => {
        setIsOnline(false);
    };
    useEffect(() => {
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
    
    
        return () => {
          window.removeEventListener('online', handleOnline);
          window.removeEventListener('offline', handleOffline);
          
        };
      }, []);
    //End of checking internet connection

    const getDialogWidth = () => {
        const columnCount = cloIdss?.length || 0;  // Get the number of columns from cloIdss array
        const baseWidth = 500; // Default base width for the dialog
      
        // Calculate the width based on the number of columns
        const dynamicWidth = baseWidth + columnCount * 30;  // Increase width by 30px per column (or adjust as needed)
      
        return dynamicWidth;
    };

    const combinedData: any = {};
    // Iterate through the data and group entries by studentSerialNumber
    surveyData?.forEach((entry: any) => {
        const serialNumber = entry.studentSerialNumber;
        if (!combinedData[serialNumber]) {
            combinedData[serialNumber] = [];
        }
        combinedData[serialNumber].push(entry);
    });
    const combinedArrayData = Object.values(combinedData);

    // console.log("offeredCourseData", offeredCourseData)

    const handleViewSurvey = (row: any) => {
        if(!isOnline){
            alert("Please check internet connection.");
        }
        let filteredData = offeredCourseData?.filter((course: any) => course.section === row.getValue('sectionNumber'));
        console.log("filteredData", filteredData);
        setFilteredDetails(filteredData);

        setOpenSurveyModal(true);
        if (accessToken !== null) {
            axios.get(`${readAPI.getSurveyData}${row.getValue('id')}`, {
                headers: {
                    "x-api-key": accessToken
                }
            })
                .then((res) => {
                    const data = res.data
                    const cloIds: any = [...new Set(data.obj.map((item: any) => item.cloId))].sort();
                    setCloIdss(cloIds);
                    setSurveyData(data.obj);
                    setIsSurveyDataError(false);

                })
                .catch((err) => {
                    console.log(err);
                    setIsSurveyDataError(true);
                });
        }
    }
    const handleCloseSurveyModal = () => {
        setOpenSurveyModal(false);
    }

    const [courseCodes, setCourseCodes] = useState()
    const handleViewAssessment = (row: any) => {
        const courseCodes = row.getValue('courseCode');
        setCourseCodes(courseCodes);
        setOpenViewAssessmentModal(true);
    }
    const handleCloseViewAssessmentModal = () => {
        setOpenViewAssessmentModal(false);
    }


    const goals = useSelector((state: RootState) => state.sps.goals);

    const handleViewGoal = (row: any) => {
        const selectedIndex = row.index;
        const selectedGoal = goals[selectedIndex];

        if (selectedGoal) {
            setGoalData(selectedGoal);
            setOpenViewGoalModal(true);
        } else {
            console.error("Goal not found for index:", selectedIndex);
        }
    }
    const handleCloseViewGoalModal = () => {
        setOpenViewGoalModal(false);
    }

    const strategicPlans = useSelector((state: RootState) => state.sps.strategicPlans)
    const handleViewStrategicPlan = (row: any) => {
        const selectedIndex = row.index;
        const selectedStrategicPlan = strategicPlans[selectedIndex];
        if (selectedStrategicPlan) {
            setStrategicPlanData(selectedStrategicPlan);
            setOpenViewStrageticPlanModal(true)
        }
    }

    const initiatives = useSelector((state: RootState) => state.sps.initiatives)
    // console.log("initiatives", initiativeData)
    const handleViewInitiative = (row: any) => {
        const selectedIndex = row.index;
        const selectedInitiative = initiatives[0][selectedIndex];
        if (selectedInitiative) {
            setInitiativeData(selectedInitiative);
            setOpenViewInitiativeModal(true)
        }
    }
    const kpis: any = useSelector((state: RootState) => state.sps.strategicPlanDetailed);
    const handleViewKPI = (row: any) => {
        const selectedIndex = row.index;
        const selectedKpis = kpis[0]?.data?.kpiDetails[selectedIndex];
        if (selectedKpis) {
            setKpisData(selectedKpis);
            setOpenViewKpisModal(true)
        }
    }

    const kpisValue = useSelector((state: RootState) => state.sps.strategicPlanDetailed);
    const handleViewKPIValue = (row: any) => {
        const selectedIndex = row.index;
        const selectedKpisValue = kpisValue[0]?.data?.kpiValueDetails[selectedIndex];
        if (selectedKpisValue) {
            setKpisValueData(selectedKpisValue);
            setOpenViewKpisValueModal(true)
        }
    }




    let programList = useSelector((state: RootState) => state.programListData.data);
    let campusList = useSelector((state: RootState) => state.campusListData.data);
    let departmentList = useSelector((state: RootState) => state.departmentListData.data);
    let collegeList = useSelector((state: RootState) => state.collegeListData.data);

   
    const programMap: any = {}
    programList?.data?.forEach((item: any) => {
        programMap[item.programId] = item.programTitle
    })
    
    const departmentMap: any = {}
    departmentList?.data?.forEach((item: any) => {
        departmentMap[item.departmentId] = item.departmentName
    })

    
    const campusMap: any = {}
    campusList?.data?.forEach((item: any) => {
        campusMap[item.campusId] = item.campusName
    })

    

    const collegeMap: any = {}
    collegeList?.data?.forEach((item: any) => {
        collegeMap[item.collegeId] = item.collegeName
    })

    const handleViewStudyPlan = (row: any) => {
        setOpenViewStudyPlanModal(true);
        axios.get(`${STUDYPLAN_URL}/study-plan/${row.getValue('id')}`, {
            headers: {
                "x-api-key": accessToken
            }
        })
            .then((res) => {
                setStudyPlanData(res.data.obj);
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const handleCloseViewStudyPlanModal = () => {
        setOpenViewStudyPlanModal(false);
        setStudyPlanData(null);
    }

    //@ change password functions
    const [userNameRow, setUserNameRow] = useState<any>();
    const handleChangePassword = (row: any) => {
        // alert(`${row.getValue('userName')}`)
        // let userNameRow = row.getValue('userName')
        setUserNameRow(row.original.userName)
        setOpenViewPasswordChange(true)
    }
    const handleCloseChangePasswordModal = () => {
        setOpenViewPasswordChange(false);
        // setStudyPlanData(null);
    }

    const handleNewPasswordChange = (event: any) => {
        setNewPassword(event.target.value);
        setPasswordError('');
    };

    const handleConfirmPasswordChange = (event: any) => {
        setConfirmPassword(event.target.value);
        setPasswordError('');
    };

    const handleSubmitChangedPassword = () => {
        if (newPassword !== confirmPassword) {
            setPasswordError('New Password and confirm password should be same!');
        } else {
            axios.post(`${BASE_URL}/IDENTITY-SERVICE/login/resetPasswords`,
                {
                    "userName": userNameRow,
                    "newPassword": newPassword
                }
                , {
                    headers: {
                        'x-api-key': accessToken,
                        'Access-Control-Allow-Origin': '*'
                    }
                })
                .then(function (response) {
                    if (response.status === 200) {
                        setSnackBarHandler({
                            open: true,
                            message: (response.data.code === "200.200") ? (`Password has been updated successfully`) : (response.data.message),
                            severity: (response.data.code === "200.200") ? ("success") : ("error")
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setSnackBarHandler({
                        open: true,
                        message: error.message,
                        severity: "error"
                    })
                });
            handleCloseChangePasswordModal();
        }
    };

    const handleDownloadSurveyData = () => {
        const exportToPDF = async () => {
            const modalElement: any = document.getElementById('modalId');
            const modalContentElement: any = modalElement.querySelector('.surveyData');
            const modalContentHeight = modalContentElement.scrollHeight;
            let currentPosition = 0;
            const screenshots = [];
        
            while (currentPosition < modalContentHeight) {
                modalContentElement.scrollTop = currentPosition;
        
                // Wait for scroll to update and render properly
                await new Promise((resolve) => {
                    setTimeout(resolve, 500);
                });
        
                const canvas = await html2canvas(modalContentElement, {
                    scale: 2,
                    useCORS: true
                });
        
                screenshots.push(canvas.toDataURL('image/png', 1));
                currentPosition += modalContentElement.clientHeight;
            }
        
            const thisPDF = new jsPDF('p', 'mm', 'a4', true);
            const pageWidth = thisPDF.internal.pageSize.getWidth();
            const pageHeight = thisPDF.internal.pageSize.getHeight();
        
            screenshots.forEach((dataURL, index) => {
                if (index > 0) {
                    thisPDF.addPage();  // Add new page if not the first one
                }
        
                // Get the canvas image width and height
                const img = new Image();
                img.src = dataURL;
                // Wait for the image to load
                img.onload = () => {
                    const imgWidth = img.width;
                    const imgHeight = img.height;

                    // Calculate the scaling to fit the image inside the PDF page
                    let width = pageWidth;
                    let height = (imgHeight * pageWidth) / imgWidth;

                    // If the image height is greater than the page height, scale it down to fit the page
                    if (height > pageHeight) {
                        height = pageHeight;
                        width = (imgWidth * pageHeight) / imgHeight;
                    }

                    // Add the image to the PDF with the calculated width and height
                    thisPDF.addImage(dataURL, 'PNG', 0, 0, width, height);
                    // Save the PDF after all pages are processed
                    if (index === screenshots.length - 1) {
                        thisPDF.save('survey_data.pdf');
                    }
                };
            });
        };
        exportToPDF();
    }
    const dispatch = useDispatch();

    const handleDeleteStrategic = (row: any) => {
        alertDeleteConfirm().then((result: any) => {
            if (result.isConfirmed) {
                dispatch(removeStrategicPlanByIndex(row.index));
                deletedFun();
                alertSuccess("Strategic Plan Deleted Successfully");
            }

            
        });

    }
    const handleDeleteInitiative = (row: any) => {
        alertDeleteConfirm().then((result: any) => {
            if (result.isConfirmed) {
                dispatch(removeInitiative(row.index));
                axios.delete(`${deleteSPS.DeleteInitiative}${row.original.initiativeId}`, {
                    headers: {
                        'x-api-key': accessToken,
                        'Content-Type': 'application/json',
                        'Accept-language' : 'en'
                    }
                })
                    .then((response) => {
                        if (response.data.code === "200.200") {
                            deletedFun();
                            alertSuccess("Initiative Deleted Successfully");
                        }
                        else if (response.data.code === "200.403") {
                            alertError(`${response.data.message}`)
                        }
                    })
                    .catch((error) => {
                        console.error("Error deleting KPI:", error);
                        alertError("Failed to delete KPI. Please try again.");
                    });

            }
        })

    }
    const handleDeleteKPI = (row: any) => {
        alertDeleteConfirm().then((result: any) => {
            if (result.isConfirmed) {
                dispatch(removeKpi(row.index));
                axios.delete(`${deleteSPS.DeleteKpi}${row.original.kpiId}`, {
                    headers: {
                        'x-api-key': accessToken,
                        'Content-Type': 'application/json',
                        'accept-language': "en"
                    }
                })
                    .then((response) => {
                        if(response.data.code === "200.403"){
                            alertError(response.data.error);
                        }
                        if (response.data.code === "200.200") {
                            deletedFun();
                            alertSuccess("KPI Deleted Successfully");
                        }
                    })
                    .catch((error) => {
                        console.error("Error deleting KPI:", error);
                        alertError("Failed to delete KPI. Please try again.");
                    });

            }
        })

    }
    const handleDeleteKPIValue = (row: any) => {
        alertDeleteConfirm().then((result: any) => {
            if (result.isConfirmed) {
                dispatch(removeKpiValue(row.index));
                console.log("row.index", row.index)
                axios.delete(`${deleteSPS.DeleteKpiValue}${row.original.kpiValueId}`, {
                    headers: {
                        'x-api-key': accessToken,
                        'Content-Type': 'application/json',
                        'accept-language': "en"
                    }
                })
                    .then((response) => {
                        if(response.data.code === "200.403"){
                            alertError(response.data.error);
                        }
                        if (response.data.code === "200.200") {
                            deletedFun();
                            alertSuccess("KPI Value Deleted Successfully");
                        }
                        
                    })
                    .catch((error) => {
                        console.error("Error deleting KPI:", error);
                        alertError("Failed to delete KPI. Please try again.");
                    });
            }
        })
    }

    const handleDeleteGoal = (row: any) => {
        alertDeleteConfirm().then((result: any) => {
            if (result.isConfirmed) {
                dispatch(removeGoal(row.index));
                deletedFun();
                alertSuccess("Goal Deleted Successfully");
            }
        })
    }

    const handleEditRow = (row: any) => {
        // Enter editing mode

        // console.log("studyPlanCompleteData>>>>>>>>>", studyPlanCompleteData);
        // console.log("row>>>>>>>>>>>>>>>", row);
        // console.log("row>>>>>>>>>>>>>>>gggg", studyPlanCompleteData[row.index]?.offeredProgram);
        // const studData: any = studyPlanCompleteData[row.index]?.offeredProgram;

        if (columnName === 'ViewCreatedStudyPlan') {
            setStudyPlanOfferedProgram(studyPlanCompleteData[row.index]?.offeredProgram);
        }
        if (columnName === 'ViewAssignTaskReport') {
            if (accessToken !== null) {
                axios.get(`${Annual_Program_Report}/annual-program-report/task-detail/by-report?reportId=${row.original.reportId}`, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        setAssignTaskTasks(res.data.obj[0].tasks);
                        dispatch(assignTaskData(res.data.obj[0].tasks))
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            // setAssignTaskTasks(assignTaskCompleteData[row.index]?.tasks);
        }
        if (columnName === 'ViewGoalData') {
            setGoalDatas(goals[row.index]?.subGoal)
            setGoalMappingToKpi(goals[row.index]?.goalMappingToKpi)
        }



        setOpenModal(true);
        setCurrentRowIndex(row.index);

        //alert("Edit Row");
        // console.log(row.original);

        let values: any = row.original;
        // alert("Save Row Edits");
        console.log("Values ===> ", values);
        // console.log("Row ===> ", row);

        //////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////
        ////////////////////////  API CALL  //////////////////////////
        // const userLocalStorage: any = EncryptConfig.decryptData("user");

        // const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');



        if (userLocalStorage !== null && userLocalStorage !== undefined) {
            const loggedInUser = userLocalStorage.userName;


            // let accessToken: any = Cookies.get("accessToken");

            if (accessToken === undefined || accessToken === null) {
                accessToken = null;
            }

            if (accessToken !== null) {
                //////////////////////////////////////////@
                let url = "";
                let newValues: any = null;
                if (columnName === "ViewUsers") {
                    url = updateAPI.Users;
                    // Fetch all the properties of the object [...tableData]
                    newValues = {
                        "userId": values.userId,
                        "firstName": values.firstName,
                        "lastName": values.lastName,
                        "userName": values.userName,
                        "password": values.password,
                        "emailId": values.emailId,
                        "collegeId": values.collegeId,
                        "campusId": values.campusId,
                        "departmentId": values.departmentId,
                        "universityId": values.universityId,
                        "contactNo": values.contactNo,
                        "preferredLanguage": values.preferredLanguage,
                        "loggedInUser": loggedInUser,
                        "active": values.active === "true" ? true : false,
                        "staff": values.staff === "true" ? true : false,
                        "superUser": values.superUser === "true" ? true : false
                    };
                } else if (columnName === "ViewRoles") {
                    url = updateAPI.Roles;

                    newValues = {
                        "roleId": values.roleId,
                        "roleName": values.roleName,
                        "roleDescription": values.roleDescription,
                        "loggedInUser": loggedInUser,
                        "active": values.active === "true" ? true : false
                    };
                } else if (columnName === "ViewGroups") {
                    url = updateAPI.Groups;

                    // Fetch all the properties of the object [...tableData]
                    newValues = {
                        "grpId": values.grpId,
                        "grpName": values.grpName,
                        "grpDescription": values.grpDescription,
                        "active": values.active === "true" ? true : false,
                        "loggedInUser": loggedInUser
                    }

                } else if (columnName === "ViewApps") {
                    url = updateAPI.Apps;

                    // Fetch all the properties of the object [...tableData]
                    newValues = {
                        "appId": values.appId,
                        "appName": values.appName,
                        "appDescription": values.appDescription,
                        "appUrl": values.appUrl,
                        "appName_Ar": values.appName_Ar,
                        "appIcon": values.appIcon,
                        "appOrder": values.appOrder,
                        "active": values.active === "true" ? true : false,
                        "loggedInUser": loggedInUser
                    }

                } else if (columnName === "ViewAppForm") {
                    url = updateAPI.AppForm;

                    // Fetch all the properties of the object [...tableData]
                    newValues = {
                        "formId": values.formId,
                        "moduleName": values.moduleName,
                        "formName": values.formName,
                        "formUrl": values.formUrl,
                        "formName_Ar": values.formName_Ar,
                        "formIcon": values.formIcon,
                        "appId": values.appId,
                        "active": values.active === "true" ? true : false
                    }

                } else if (columnName === "ViewGroupRole") {
                    url = updateAPI.GroupRole;

                    // Fetch all the properties of the object [...tableData]
                    newValues = {
                        "groupRoleId": values.groupRoleId,
                        "roleId": values.roleId,
                        "grpId": values.grpId,
                        "grpRoleDescription": values.grpRoleDescription,
                        "loggedInUser": loggedInUser,
                        "active": values.active === "true" ? true : false
                    }
                } else if (columnName === "ViewUserGroup") {
                    url = updateAPI.UserGroup;

                    // alert(values.active)

                    // Fetch all the properties of the object [...tableData]
                    newValues = {
                        "userGroupId": values.userGroupId,
                        "userId": values.userId,
                        "grpId": values.grpId,
                        "active": values.active === "true" ? true : false
                    }
                } else if (columnName === "ViewRoleApp") {
                    url = updateAPI.RoleApp;

                    // Fetch all the properties of the object [...tableData]
                    newValues = {
                        "privilegeId": values.privilegeId,
                        "roleId": values.roleId,
                        "formId": values.formId,
                        "appId": values.appId,
                        "loggedInUser": loggedInUser,
                        "createPermission": values.createPermission === "true" ? true : false,
                        "readPermission": values.readPermission === "true" ? true : false,
                        "updatePermission": values.updatePermission === "true" ? true : false,
                        "deletePermission": values.deletePermission === "true" ? true : false,
                        "active": values.active === "true" ? true : false
                    }
                }else if (columnName === "ViewCollege") {
                    url = updateAPIS.UpdateCollege;
                    newValues = {
                        "collegeId": values.collegeId,
                        "collegeName": values.collegeName,
                        "collegeEmailId": values.collegeEmailId,
                        "collegeMission": values.collegeMission,
                        "collegeVision": values.collegeVision,
                        "collegeFaxNumber": values.collegeFaxNumber,
                        "collegePhoneNumber": values.collegePhoneNumber,
                        "collegeWebAddress": values.collegeWebAddress,
                        "addressLine1": values.addressLine1,
                        "addressLine2": values.addressLine2,
                        "addressLine3": values.addressLine3,
                        "province": values.province,
                        "zipCode": values.zipCode,
                        "country": values.country,
                        "active": values.active,
                        "loggedInUser": loggedInUser,
                    }
                }else if (columnName === "ViewUniversity") {
                    url = updateAPIS.UpdateUniversity;
                    newValues = {
                        "universityId": values.universityId,
                        "universityName": values.universityName,
                        "universityEmailId": values.universityEmailId,
                        "universityMission": values.universityMission,
                        "universityVision": values.universityVision,
                        "universityFaxId": values.universityFaxId,
                        "universityPhoneNumber": values.universityPhoneNumber,
                        "universityWebAddress": values.universityWebAddress,
                        "addressLine1": values.addressLine1,
                        "addressLine2": values.addressLine2,
                        "addressLine3": values.addressLine3,
                        "province": values.province,
                        "zipCode": values.zipCode,
                        "country": values.country,
                        "active": values.active,
                        "loggedInUser": loggedInUser,
                    }
                }else if (columnName === "ViewCampus") {
                    url = updateAPIS.UpdateCampus;
                    newValues = {
                        "campusId": values.campusId,
                        "campusName": values.campusName,
                        "campusEmailId": values.campusEmailId,
                        "campusMission": values.campusMission,
                        "campusVision": values.campusVision,
                        "campusFaxNumber": values.campusFaxNumber,
                        "campusPhoneNumber": values.campusPhoneNumber,
                        "campusWebAddress": values.campusWebAddress,
                        "addressLine1": values.addressLine1,
                        "addressLine2": values.addressLine2,
                        "addressLine3": values.addressLine3,
                        "province": values.province,
                        "zipCode": values.zipCode,
                        "country": values.country,
                        "active": values.active,
                        "loggedInUser": loggedInUser,
                    }
                }else if (columnName === "ViewDepartment") {
                    url = updateAPIS.UpdateDepartment;
                    newValues = {
                        "departmentId": values.departmentId,
                        "departmentName": values.departmentName,
                        "departmentEmailId": values.departmentEmailId,
                        "departmentMission": values.departmentMission,
                        "departmentVision": values.departmentVision,
                        "departmentFaxNumber": values.departmentFaxNumber,
                        "departmentPhoneNumber": values.departmentPhoneNumber,
                        "departmentWebAddress": values.departmentWebAddress,
                        "addressLine1": values.addressLine1,
                        "addressLine2": values.addressLine2,
                        "addressLine3": values.addressLine3,
                        "departmentChair": values.departmentChair,
                        "active": values.active,
                        "loggedInUser": loggedInUser,
                    }
                }else if (columnName === "ViewProgram") {
                    url = updateAPIS.UpdateProgram;
                    newValues = {
                        "programId": values.programId,
                        "programApprovalDate": values.programApprovalDate,
                        "programDesc": values.programDesc,
                        "programDuration": values.programDuration,
                        "programMission": values.programMission,
                        "programStartDate": values.programStartDate,
                        "programTitle": values.programTitle,
                        "programTotalCredits": values.programTotalCredits,
                        "programVision": values.programVision,
                        "programWebAddress": values.programWebAddress,
                        "active": values.active,
                        "loggedInUser": loggedInUser,
                    }
                }else if (columnName === "ViewProgramType") {
                    url = updateAPIS.UpdateProgramType;
                    newValues = {
                        "programTypeId": values.programTypeId,
                        "programDesc": values.programDesc,
                        "programName": values.programName,
                        "programRemarks": values.programRemarks,
                        "active": values.active,
                        "loggedInUser": loggedInUser,
                    }
                }else if (columnName === "ViewProgramObjective") {
                    url = updateAPIS.UpdateProgramObjective;
                    newValues = {
                        "id": values.id,
                        "remarks": values.remarks,
                        "soDescription": values.soDescription,
                        "soId": values.soId,
                        "programId": values.programId,
                        "active": values.active,
                        "loggedInUser": loggedInUser,
                    }
                }else if (columnName === "ViewCourseDetails") {
                    url = updateAPIS.UpdateCourse;
                    newValues = {
                        "courseCode": values.courseCode,
                        "academicYearOffered": values.academicYearOffered,
                        "alhChLaboratory": values.alhChLaboratory,
                        "alhChLecture": values.alhChLecture,
                        "alhChOther": values.alhChOther,
                        "alhChTutorial": values.alhChTutorial,
                        "alhOlhAssignment": values.alhOlhAssignment,
                        "alhOlhLibrary": values.alhOlhLibrary,
                        "alhOlhOther": values.alhOlhOther,
                        "alhOlhResearch": values.alhOlhResearch,
                        "alhOlhStudy": values.alhOlhStudy,
                        "courseCoRequisite": values.courseCoRequisite,
                        "courseCreditHours": values.courseCreditHours,
                        "courseDesc": values.courseDesc,
                        "courseLevel": values.courseLevel,
                        "courseName": values.courseName,
                        "coursePrerequisite": values.coursePrerequisite,
                        "courseTypeNames": values.courseTypeNames,
                        "courseTypeId": values.courseTypeId,
                        "frAccomodation": values.frAccomodation,
                        "frOtherResources": values.frOtherResources,
                        "frTechnologyResources": values.frTechnologyResources,
                        "labHoursPerWeek": values.labHoursPerWeek,
                        "moiBlended": values.moiBlended,
                        "moiCorrespondence": values.moiCorrespondence,
                        "moiElearning": values.moiElearning,
                        "moiOthers": values.moiOthers,
                        "moiTraditionalClassroom": values.moiTraditionalClassroom,
                        "requiredElective": values.requiredElective,
                        "sadCommittee": values.sadCommittee,
                        "sadDate": values.sadDate,
                        "sadReferenceNo": values.sadReferenceNo,
                        "studentAcademicSupport": values.studentAcademicSupport,
                        "studyPlan": values.studyPlan,
                        "teachingHoursPerWeek": values.teachingHoursPerWeek,
                        "active": values.active,
                        "loggedInUser": loggedInUser,
                    }
                }else if (columnName === "ViewCourseType") {
                    url = updateAPIS.UpdateCourseType;
                    newValues = {
                        "id": values.id,
                        "courseTypeDesc": values.courseTypeDesc,
                        "courseTypeName": values.courseTypeName,
                        "courseTypeRemarks": values.courseTypeRemarks,
                        "active": values.active,
                        "loggedInUser": loggedInUser,
                    }
                }else if (columnName === "ViewBook") {
                    url = updateAPIS.UpdateBook;
                    newValues = {
                        "id": values.id,
                        "author": values.author,
                        "description": values.description,
                        "edition": values.edition,
                        "editor": values.editor,
                        "isbn10": values.isbn10,
                        "isbn13": values.isbn13,
                        "name": values.name,
                        "publisher": values.publisher,
                        "websiteUrl": values.websiteUrl,
                        "yearOfPublication": values.yearOfPublication,
                        "active": values.active,
                        "loggedInUser": loggedInUser,
                    }
                }else if (columnName === "ViewAssessments") {
                    url = updateAPIS.UpdateAssessment;
                    newValues = {
                        "id": values.id,
                        "assessmentDate": values.assessmentDate,
                        "assessmentId": values.assessmentId,
                        "assessmentTime": values.assessmentTime,
                        "assessmentTypeId": values.assessmentTypeId,
                        "maximumMarks": values.maximumMarks,
                        "noOfQuestions": values.noOfQuestions,
                        "offeredCourseId": values.offeredCourseId,
                        "projectDescription": values.projectDescription,
                        "projectTitle": values.projectTitle,
                        "sessionType": values.sessionType,
                        "supervisorName": values.supervisorName,
                        "examiner1Name": values.examiner1Name,
                        "examiner2Name": values.examiner2Name,
                        "userName": values.userName,
                        "active": values.active,
                        "loggedInUser": loggedInUser,
                    }
                }else if (columnName === "ViewAssessmentType") {
                    url = updateAPIS.UpdateAssessmentType;
                    newValues = {
                        "assessmentTypeId": values.assessmentTypeId,
                        "assessmentTypeName": values.assessmentTypeName,
                        "description": values.description,
                        "active": values.active,
                        "loggedInUser": loggedInUser,
                    }
                }else if (columnName === "ViewStrategicPlan") {
                    url = updateAPIS.UpdateAssessmentType;
                    newValues = {
                        "index": values.index,
                        "strategicPlanName": values.strategicPlanName,
                        "description": values.description,
                        "startDate": values.startDate,
                        "endDate": values.endDate,
                        "initiativeManager": values.initiativeManagerId,
                        "active": values.active
                    }
                }else if (columnName === "ViewInitiative") {
                    url = updateAPIS.UpdateInitiative;
                    newValues = {
                        "initiativeName": values.initiativeName,
                        "manager": values.managerId,
                        "executiveManager": values.executiveManagerId,
                        "startDate": values.startDate,
                        "endDate": values.endDate,
                        "strategicPlanId": values.strategicPlanId,
                        "initiativeId": values.initiativeId,
                        "active": values.active
                    }
                }else if (columnName === "ViewKPI") {
                    url = updateAPIS.UpdateKPI;
                    newValues = {
                        "kpiName": values.kpiName,
                        "description": values.description,
                        "degree": values.degreeId,
                        "strategicPlanId": values.strategicPlanId,
                        "kpiId": values.kpiId,
                        "active": values.active
                    }
                }else if (columnName === "ViewKPIValue") {
                    url = updateAPIS.UpdateKpiValue;
                    newValues = {
                        "program": values.programId,
                        "kpiName": values.kpiName,
                        "target": values.target,
                        "benchmark": values.benchmark,
                        "newtarget": values.newTarget,
                        "minimumTarget": values.minimumTarget,
                        "kpiValueId": values.kpiValueId,
                        "strategicPlanId": values.strategicPlanId,
                        "kpiId": values.kpiId,
                        "active": values.active
                    }
                }else if (columnName === "ViewGoalData") {
                    url = updateAPIS.UpdateAssessmentType;
                    newValues = {
                        "goalName": values.goalName,
                        "goalDescription": values.goalDescription,
                        "goalManager": values.goalManagerId,
                        "initiative": values.initiativeId,
                        "mappedKpi": values.mappedKpi,
                        "active": values.active
                    }
                }else if (columnName === "ViewManualScheduleCourse") {
                    url = updateAPIScheduleCourse.UpdateOfferedCourse;
                    newValues = {
                        "offeredCourseId": values.offeredCourseId,
                        "courseCode": values.courseCode,
                        "collegeId": values.collegeId,
                        "campusId": values.campusId,
                        "programId": values.programId,
                        "departmentId": values.departmentId,
                        "instructorId": values.instructorId,
                        "noOfStudent": values.noOfStudent,
                        "section": values.section,
                        "sessionType": values.sessionType,
                        "active": values.active,
                        "loggedInUser": loggedInUser,
                    }
                }else if (columnName === "ViewSessionTypeData") {
                    url = updateAPIScheduleCourse.UpdateSessionType;
                    newValues = {
                        "id": values.id,
                        "name": values.name,
                        "description": values.description,
                        "active": values.active,
                        "loggedInUser": loggedInUser,
                    }
                }else if (columnName === "ViewAcademicYearData") {
                    url = updateAPIScheduleCourse.UpdateAcademicYear;
                    newValues = {
                        "id": values.id,
                        "year": values.year,
                        "semester": values.semester,
                        "active": values.active,
                        "loggedInUser": loggedInUser,
                    }
                }else if (columnName === "ViewCourseCoordinatorData") {
                    url = updateAPIScheduleCourse.UpdateCourseCoordinator;
                    newValues = {
                        "id": values.id,
                        "courseCode": values.courseCode,
                        "coordinatorId": values.coordinatorId,
                        "active": values.active,
                        "loggedInUser": loggedInUser,
                    }
                }else if (columnName === "ViewProjectTemplate") {
                    url = updateAPIS.UpdateProjectSubComponent;
                    newValues = {
                        "id": values.subComponentId,
                        "subComponentName": values.subComponentName,
                        "clos": values.clos,
                        "maxMarks": values.maxMarks,
                        "active": values.active,
                        "loggedInUser": loggedInUser,
                    }
                }else if (columnName === "ViewCoordinator") {
                    url = updateAPIS.UpdateCoordinator;
                    newValues = {
                        "id": values.id,
                        "courseCode": values.courseCode,
                        "coordinatorId": values.coordinatorId,
                        "active": values.active,
                    }
                }else if (columnName === "ViewAllMeeting") {
                    url = updateAPIS.UpdateCoordinator;
                    newValues = {
                        "id": values.id,
                        "meetingId": values.meetingId,
                        "parentMeetingId": values.parentMeetingId,
                        "organizer": values.organizer,
                        "date": values.date,
                        "startTime": values.startTime,
                        "endTime": values.endTime,
                        "location": values.location,
                        "department": values.department,
                        "agendaTopic": values.agendaTopic,
                        "active": values.active,
                        "status": values.status
                    }
                } else if (columnName === "ViewSurvey") {
                    url = updateAPIS.UpdateSurveyFormLink;
                    newValues = {
                        "id": values.id,
                        "courseCode": values.courseCode,
                        "sectionNumber": values.sectionNumber,
                        "url": values.url,
                        "noOfDays": values.noOfDays,
                        "semester": values.semester,
                        "year": values.year,
                        "collegeId": values.collegeId,
                        "campusId": values.campusId,
                        "departmentId": values.departmentId,
                        "closCount": values.closCount,
                        "studentCount": values.studentCount,
                        "conductedSurveyCount": values.conductedSurveyCount,
                        "active": values.active
                    }
                }else if (columnName === "ViewLocation") {
                    url = updateAPIS.UpdateLocation;
                    newValues = {
                        "id": values.id,
                        "collegeId": values.collegeId,
                        "campusId": values.campusId,
                        "buildingNo": values.buildingNo,
                        "floorNo": values.floorNo,
                        "roomNo": values.roomNo,
                        "active": values.active
                    }
                }else if (columnName === "ViewUploadDocStatus") {
                    url = updateAPIS.UpdateDocumentType;
                    newValues = {
                        "id": values.id,
                        "title": values.title,
                        "sessionType": values.sessionType,
                        "sessionId": values.sessionId,
                        "required": values.required,
                        "collegeId": values.collegeId,
                        "displayOrder": values.displayOrder,
                        "active": values.active
                    }
                }else if (columnName === "ViewCreatedStudyPlan") {
                    url = updateAPIS.UpdateStudyPlan;
                    newValues = {
                        "id": values.id,
                        "title": values.title,
                        "programId": values.programId,
                        "departmentId": values.departmentId,
                        "campusId": values.campusId,
                        "collegeId": values.collegeId,
                        "approvalDate": values.approvalDate,
                        "creditHours": values.creditHours,
                        "active": values.active
                    }
                }else if (columnName === "ViewCommittee") {
                    url = updateAPIS.UpdateCommittee;
                    newValues = {
                        "id": values.id,
                        "committeeName": values.committeeName,
                        "committeeDescription": values.committeeDescription,
                        "committeeChair": values.committeeChairId,
                        "department": values.departmentId,
                        "campus": values.campusId,
                        "college": values.collegeId,
                        "active": values.active
                    }
                }else if (columnName === "ViewCommitteeMember") {
                    url = updateAPIS.UpdateCommitteeMember;
                    newValues = {
                        "id": values.id,
                        "memberId": values.memberId,
                        "committeeId": values.committeeId,
                        "memberList": values.memberList,
                        "active": values.active
                    }
                }else if (columnName === "ViewAnnualProgramReport") {
                    url = updateAPIS.UpdateAnnualProgramReport;
                    newValues = {
                        "id": values.id,
                        "sectionName": values.sectionName,
                        "description": values.description,
                        "orderValue": values.orderValue,
                        "academicYear": values.academicYear,
                        "active": values.active
                    }
                }else if (columnName === "ViewAssignTaskReport") {
                    url = updateAPIS.UpdateAssignTaskReport;
                    newValues = {
                        "id": values.id,
                        "reportId": values.reportId,
                        "programId": values.programId,
                        "departmentId": values.departmentId,
                        "collegeId": values.collegeId,
                        "academicYear": values.academicYear,
                        "responsible": values.responsible,
                        "sectionId": values.sectionId,
                        "active": values.active
                    }
                }else {
                    url = "";
                    newValues = null;
                    tableData[row.index] = values;
                    //send/receive api updates here, then refetch or update local table data for re-render
                    setTableData([...tableData]);
                }

                if (url !== "" && newValues !== null) {
                    setOriginalValues(newValues);
                    setURL(url);
                }
                else {
                    console.log("URL is empty");
                    alert("URL is empty");
                }
            }
            else {
                alert("Please login first");
                navigate('/login');
            }
        }
        else {
            alert("Please login first");
            navigate('/login');
        }
        // enterEditingMode(row.index);
    }

    return (
        <div>

            <div className={styles.container}>
                <div className={styles.insideTableContainer}
                    style={{
                        height: (windowSize[0] < 600) ? ('400px') : ('100%'),
                        overflowY: (windowSize[0] < 600) ? ('auto') : ('unset'),
                    }}
                >
                   {loadingStatus ? <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        <Box sx={{
                            height: "300px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column"
                        }}>
                            <div className="lds-roller">
                                <div>
                                </div>
                                <div>
                                </div>
                                <div>
                                </div>
                                <div>
                                </div>
                                <div>
                                </div>
                                <div>
                                </div>
                                <div>
                                </div>
                                <div>
                                </div>
                            </div>

                        </Box>
                    </Box> :
                        data && data.length > 0
                            ? (
                                <MaterialReactTable
                                    muiTableBodyRowProps={{
                                        sx: {
                                            height: '50px'
                                        }
                                    }}

                                    muiTableBodyCellProps={{
                                        sx: {
                                            p: '2px 16px',
                                            //   wordWrap: 'break-word'
                                            // whiteSpace: 'pre-wrap'

                                            // whiteSpace: 'nowrap',
                                            // overflow: 'hidden',
                                            // textOverflow: 'ellipsis',
                                            // maxWidth: '200px'
                                        }
                                    }}

                                    displayColumnDefOptions={{
                                        'mrt-row-actions': {
                                            muiTableHeadCellProps: {
                                                align: 'center',
                                            },
                                            size: 170,
                                        },
                                    }}
                                    columns={tableColumnsNew.map((column: any) => ({
                                        ...column,
                                        Cell: ({ cell }: { cell: MRT_Cell<any> }) => (
                                            <div title={cell.getValue<string>()}>
                                                {truncateText(cell.getValue<string>())}
                                            </div>
                                        ),
                                    }))}
                                    data={tableData}
                                    editingMode="modal" //default
                                    enableColumnOrdering
                                    enableEditing={setEnableEditing}
                                    enableClickToCopy
                                    onEditingRowSave={handleEditRow}
                                    onEditingRowCancel={handleCancelRowEdits}
                                    initialState={{
                                        density: 'compact',
                                        columnVisibility: {
                                            createdBy: false,
                                            creationDateAndTime: false,
                                            updateDateAndTime: false,
                                            updatedBy: false,
                                            creationDateTime: false,
                                            updateDateTime: false,
                                            id: false,
                                            subComponentId: false,
                                            assessmentId: false,
                                            offeredCourseId: false,
                                            assessmentTypeId: false,
                                            collegeId: false,
                                            campusId: false,
                                            departmentId: false,
                                            programId: false,
                                            committeeChairId: false,
                                            userSignature: false,
                                            userPicturePath: false,
                                            password: false,
                                            userId: false,
                                            managerId: false,
                                            executiveManagerId: false,
                                            initiativeManagerId: false,
                                            goalManagerId: false,
                                            initiativeId: false,
                                            strategicPlanId: false,
                                            degreeId: false,
                                            universityId: false,
                                            kpiValueId: false,
                                            kpiId: false
                                        }
                                    }}
                                    renderRowActions={({ row, table }) => {
                                        const rowData: any = row.original; // Access the row's data
                                        // console.log('Row Data:===========', row.original);
                                        const isPastEndDate = new Date(rowData.endDate) < new Date();
                                        const isStrategicPlanEndDate = new Date(strategicPlanEndDate) < new Date(); // Check if the endDate is in the past
                                        const isLogedInUserIsManager = loggedInUser?.userId !== rowData?.managerId;
                                        // alert(isLogedInUserIsManager);

                                        return (
                                            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: "center" }}>


                                                {(editable) && (
                                                    <Tooltip arrow placement="left" title="Edit">
                                                        <IconButton onClick={() => {
                                                            // table.setEditingRow(row)
                                                            // alert("Edit Clicked");
                                                            handleEditRow(row);
                                                        }
                                                        }>
                                                            <Edit />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}

                                                {columnName === 'ViewCreatedStudyPlan' ?
                                                    (
                                                        <>
                                                            <Tooltip arrow placement="right" title="View Study Plan">
                                                                <IconButton
                                                                    color="primary"
                                                                    // @ts-ignore
                                                                    onClick={() => handleViewStudyPlan(row)}
                                                                >
                                                                    <BsEyeFill />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>
                                                    ) : ""}

                                                {columnName === 'ViewStrategicPlan' ?
                                                    (
                                                        <>
                                                            <Tooltip arrow placement="right" title="View Goal">
                                                                <IconButton
                                                                    color="primary"
                                                                    // @ts-ignore
                                                                    onClick={() => handleViewStrategicPlan(row)}
                                                                >
                                                                    <BsEyeFill />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip arrow placement="left" title="Edit">
                                                                <IconButton onClick={() => {
                                                                    // table.setEditingRow(row)
                                                                    // alert("Edit Clicked");
                                                                    handleEditRow(row);
                                                                }
                                                                }>
                                                                    <Edit />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip arrow placement="right" title="Delete">
                                                                <IconButton
                                                                    color="error"
                                                                    // @ts-ignore
                                                                    onClick={() => handleDeleteStrategic(row)}
                                                                >
                                                                    <Delete />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>

                                                    ) : ""}


                                                {columnName === 'ViewAssignTask' ?
                                                    (
                                                        <>
                                                            <Tooltip arrow placement="right" title="View Task">
                                                                <IconButton
                                                                    color="primary"
                                                                // @ts-ignore
                                                                // onClick={() => handleViewStrategicPlan(row)}
                                                                >
                                                                    <BsEyeFill />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip arrow placement="left" title="Edit">
                                                                <IconButton onClick={() => {
                                                                    // table.setEditingRow(row)
                                                                    // alert("Edit Clicked");
                                                                    // handleEditRow(row);
                                                                }
                                                                }>
                                                                    <Edit />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip arrow placement="right" title="Delete">
                                                                <IconButton
                                                                    color="error"
                                                                // @ts-ignore
                                                                // onClick={() => handleDeleteStrategic(row)}
                                                                >
                                                                    <Delete />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>

                                                    ) : ""}


                                                {columnName === 'ViewAnnouncementQmsAdmin' ?
                                                    (
                                                        <>
                                                            <Tooltip arrow placement="right" title="View Announcement">
                                                                <IconButton
                                                                    color="primary"
                                                                // @ts-ignore
                                                                // onClick={() => handleViewStrategicPlan(row)}
                                                                >
                                                                    <BsEyeFill />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip arrow placement="left" title="Edit">
                                                                <IconButton onClick={() => {
                                                                    // table.setEditingRow(row)
                                                                    // alert("Edit Clicked");
                                                                    // handleEditRow(row);
                                                                }
                                                                }>
                                                                    <Edit />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>

                                                    ) : ""}
                                                {columnName === 'ViewInitiative' ?
                                                    (
                                                        <>
                                                            <Tooltip arrow placement="right" title="View Initiative">
                                                                <span>
                                                                    <IconButton
                                                                        color="primary"
                                                                        // @ts-ignore
                                                                        onClick={() => handleViewInitiative(row)}
                                                                    >
                                                                        <BsEyeFill />
                                                                    </IconButton>
                                                                </span>
                                                            </Tooltip>
                                                            {loggedInUser.userId === getInitiative?.executiveManager ? "" :
                                                                <>

                                                                    <Tooltip arrow placement="left" title="Edit">
                                                                        <span>
                                                                            <IconButton onClick={() => {
                                                                                // table.setEditingRow(row)
                                                                                // alert("Edit Clicked");
                                                                                handleEditRow(row);
                                                                            }

                                                                            }
                                                                                disabled={isPastEndDate || isLogedInUserIsManager}
                                                                            >
                                                                                <Edit />

                                                                            </IconButton>
                                                                        </span>
                                                                    </Tooltip>

                                                                    <Tooltip arrow placement="right" title="Delete">
                                                                        <span>
                                                                            <IconButton
                                                                                color="error"
                                                                                // @ts-ignore
                                                                                onClick={() => handleDeleteInitiative(row)}
                                                                                disabled={isPastEndDate || isLogedInUserIsManager}
                                                                            >
                                                                                <Delete />
                                                                            </IconButton>
                                                                        </span>
                                                                    </Tooltip>
                                                                </>
                                                            }



                                                        </>

                                                    ) : ""}
                                                {columnName === 'ViewKPI' ?
                                                    (
                                                        <>
                                                            <Tooltip arrow placement="right" title="View KPI">
                                                                <IconButton
                                                                    color="primary"
                                                                    // @ts-ignore
                                                                    onClick={() => handleViewKPI(row)}
                                                                >
                                                                    <BsEyeFill />
                                                                </IconButton>
                                                            </Tooltip>
                                                            {loggedInUser.userId === getInitiative?.executiveManager ? "" :
                                                                <>
                                                                    <Tooltip arrow placement="left" title="Edit">
                                                                        <IconButton onClick={() => {
                                                                            // table.setEditingRow(row)
                                                                            // alert("Edit Clicked");
                                                                            handleEditRow(row);
                                                                        }}
                                                                            disabled={isStrategicPlanEndDate}
                                                                        >
                                                                            <Edit />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip arrow placement="right" title="Delete">
                                                                        <IconButton
                                                                            color="error"
                                                                            // @ts-ignore
                                                                            onClick={() => handleDeleteKPI(row)}
                                                                            disabled={isStrategicPlanEndDate}
                                                                        >
                                                                            <Delete />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </>}
                                                        </>

                                                    ) : ""}
                                                {columnName === 'ViewKPIValue' ?
                                                    (
                                                        <>
                                                            <Tooltip arrow placement="right" title="View KPI Value">
                                                                <IconButton
                                                                    color="primary"
                                                                    // @ts-ignore
                                                                    onClick={() => handleViewKPIValue(row)}
                                                                >
                                                                    <BsEyeFill />
                                                                </IconButton>
                                                            </Tooltip>
                                                            {loggedInUser.userId === getInitiative?.executiveManager ? "" :
                                                                <>
                                                                    <Tooltip arrow placement="left" title="Edit">
                                                                        <IconButton onClick={() => {
                                                                            // table.setEditingRow(row)
                                                                            // alert("Edit Clicked");
                                                                            handleEditRow(row);

                                                                        }
                                                                        }
                                                                            disabled={isStrategicPlanEndDate}
                                                                        >
                                                                            <Edit />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip arrow placement="right" title="Delete">
                                                                        <IconButton
                                                                            color="error"
                                                                            // @ts-ignore
                                                                            onClick={() => handleDeleteKPIValue(row)}
                                                                            disabled={isStrategicPlanEndDate}
                                                                        >
                                                                            <Delete />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </>
                                                            }
                                                        </>

                                                    ) : ""}


                                                {columnName === 'ViewGoalData' ?
                                                    (
                                                        <>
                                                            <Tooltip arrow placement="right" title="View Goal">
                                                                <IconButton
                                                                    color="primary"
                                                                    // @ts-ignore
                                                                    onClick={() => handleViewGoal(row)}
                                                                >
                                                                    <BsEyeFill />
                                                                </IconButton>
                                                            </Tooltip>
                                                            {loggedInUser.userId === getInitiative?.executiveManager ? "" :
                                                                <>
                                                                    <Tooltip arrow placement="left" title="Edit">
                                                                        <IconButton onClick={() => {
                                                                            // table.setEditingRow(row)
                                                                            // alert("Edit Clicked");
                                                                            handleEditRow(row);
                                                                        }
                                                                        }>
                                                                            <Edit />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip arrow placement="right" title="Delete">
                                                                        <IconButton
                                                                            color="error"
                                                                            // @ts-ignore
                                                                            onClick={() => handleDeleteGoal(row)}
                                                                        >
                                                                            <Delete />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </>
                                                            }
                                                        </>
                                                    ) : ""}

                                                {columnName === "ViewSurvey" ? (
                                                    <>
                                                        <Tooltip arrow placement="right" title="View Survey">
                                                            <IconButton
                                                                color="primary"
                                                                // @ts-ignore
                                                                onClick={() => handleViewSurvey(row)}
                                                            >
                                                                <BsEyeFill />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </>) : ""}
                                                { columnName === 'ViewOfferedCourses' ?
                                                    (
                                                        <>
                                                            <Tooltip arrow placement="right" title="View Syllabus">
                                                                <IconButton
                                                                    color="primary"
                                                                    // @ts-ignore
                                                                    onClick={() => handleViewAssessment(row)}
                                                                >
                                                                    <BsEyeFill />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>
                                                    ):(
                                                        <>
                                                            {(deletable) && (
                                                                <Tooltip arrow placement="right" title="Delete">
                                                                    <IconButton
                                                                        color="error"
                                                                        // @ts-ignore
                                                                        onClick={() => handleDeleteRow(row)}
                                                                    >
                                                                        <Delete />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                        </>
                                                    )}
                                                {columnName === 'ViewUsers' ?
                                                    (
                                                        <>
                                                            <Tooltip arrow placement="right" title="Change Password">
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={() => handleChangePassword(row)}
                                                                >
                                                                    <LockResetIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            {/* <Button variant="outlined">Change password</Button> */}
                                                        </>
                                                    ) : ""}



                                                {columnName === "ViewAssignTaskReport" ? (
                                                    <>
                                                        <Tooltip arrow placement="left" title="Edit">
                                                            <IconButton onClick={() => {
                                                                // table.setEditingRow(row)
                                                                // alert("Edit Clicked");
                                                                handleEditRow(row);

                                                            }
                                                            }>
                                                                <Edit />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip arrow placement="right" title="Delete">
                                                            <IconButton
                                                                color="error"
                                                                // @ts-ignore
                                                                onClick={() => handleDeleteRow(row)}
                                                            >
                                                                <Delete />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </>
                                                ) : ""}




                                            </Box>
                                        )

                                    }}
                                />
                            ) : <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    textAlign: "center",
                                    color: "grey.600",
                                    bgcolor: "background.default",
                                    p: 3,
                                    pt: 10,
                                    pb: 10,
                                    borderRadius: 1,
                                    boxShadow: 1
                                }}
                            >
                                <ErrorOutline sx={{ fontSize: 50, mb: 2, color: "grey.500" }} />
                                <Typography variant="h6" component="div" gutterBottom>
                                    Data Not Found
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    We couldn't find the data you're looking for.
                                </Typography>
                            </Box>
                    }
                </div>
            </div>

            <ViewStrategicPlanModal
                openViewStrategicPlanModal={openViewStrategicPlanModal}
                currentLang={currentLang}
                setOpenViewStrageticPlanModal={setOpenViewStrageticPlanModal}
                strategicPlanData={strategicPlanData}
            />
            <ViewInitiativeModal
                openViewInitiativeModal={openViewInitiativeModal}
                currentLang={currentLang}
                setOpenViewInitiativeModal={setOpenViewInitiativeModal}
                initiativeData={initiativeData}
            />

            <ViewKpisModal
                openViewKpisModal={openViewKpisModal}
                currentLang={currentLang}
                setOpenViewKpisModal={setOpenViewKpisModal}
                kpisData={kpisData}
            />

            <ViewKpisValueModal
                openViewKpisValueModal={openViewKpisValueModal}
                currentLang={currentLang}
                setOpenViewKpisValueModal={setOpenViewKpisValueModal}
                kpisValueData={kpisValueData}
            />

            <Dialog open={openSurveyModal}
                onClose={handleCloseSurveyModal}
                fullWidth
                maxWidth="md"
                id="modalId"
            >
                <DialogTitle>
                    <div style={{ display: "flex", justifyContent: "space-between" }}><span><DatasetLinkedIcon style={{ marginRight: "10px" }} />Survey Data</span>
                        <Download onClick={handleDownloadSurveyData} style={{cursor: 'pointer'}} /></div>
                </DialogTitle>
                <DialogContent className="surveyData" >
                    <Box
                        sx={{
                            paddingTop:
                                (windowSize[0] < 991) ? (5) : (windowSize[0] < 1200) ? (6) : (0),
                            boxShadow: "0 0 10px grey",
                            marginTop: 5,
                            marginBottom: 2
                            // width:  (windowSize[0] < 991) ? (450) : (windowSize[0] < 1200) ? (500) : (500)
                        }}

                    >
                        <Grid container spacing={2} sx={{  padding: 0, marginTop: -4 ,justifyContent: 'space-between'}} >
                            

                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{paddingLeft: 1, marginLeft:2}}>
                                <p className={styles.infoICB}>Course Code: <span className={styles.textStyle}>{filteredDetails?.[0].courseCode}</span></p>
                                <p className={styles.infoICB}>Course Name : <span className={styles.textStyle}>{filteredDetails?.[0].courseName}</span></p>
                                <p className={styles.infoICB}>Semester : <span className={styles.textStyle}>{filteredDetails?.[0].semester}</span></p>
                            </Grid>

                            <Grid item xl={2} lg={2} md={2} sm={12} xs={12} sx={{paddingLeft: 1, marginLeft:2, marginRight:2}}>
                                <p className={styles.infoICB} style={{ display: 'inline' }}>Section : <span className={styles.textStyle}>{filteredDetails?.[0].section}</span></p>
                                <p className={styles.infoICB}><span className={styles.textStyle}>&nbsp;</span></p>
                                <p className={styles.infoICB}>Year : <span className={styles.textStyle}>{filteredDetails?.[0].academicYear}</span></p>
                            </Grid>
                        </Grid>
                    </Box>
                    { !isSurveyDataError  ? 
                        (<table style={{ borderCollapse: 'collapse', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)' }}>
                            <thead>
                                <tr>
                                    <th style={{ border: '1px solid black', padding: '8px', backgroundColor: '#4F747A', color: 'white' }}>Student SNo.</th>
                                    {cloIdss?.map((cloId: any, index: number) => (
                                        <th key={cloId} style={{ border: '1px solid black', padding: '8px', backgroundColor: 'yellow' }}>CLO{cloId}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {combinedArrayData?.map((studentData: any, index: any) =>
                                    <tr key={index}>
                                        <td style={{ border: '1px solid black', padding: '8px', backgroundColor: '#4F747A', color: 'white' }}>{studentData[0].studentSerialNumber}</td>
                                        {studentData.map((entry: any, idx: any, array: any) => (
                                            <td key={idx} style={{ border: '1px solid black', padding: '8px' }}>{entry.value}</td>
                                        ))}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        ):(
                            <Alert severity="error">Service is down. Please try after sometime.</Alert>
                        )
                    }  
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSurveyModal} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>



            {/* assessment modal */}

            <Dialog open={openViewAssessmentModal}
                onClose={handleCloseViewAssessmentModal}
                fullWidth
                maxWidth="md"
                id="modalId"

            >
                <DialogTitle>
                    <div style={{ display: "flex", justifyContent: "space-between" }}><span>Syllabus</span></div>
                </DialogTitle>
                <DialogContent className="surveyData">

                    <Grid container spacing={2} sx={{ padding: 0 }} >
                        <Grid item xl={12} lg={12} md={12} sx={{ mt: 2 }}>
                            <Syllabus setIsOpen={isOpen} isOpen={isOpen} currentLang={currentLang} courseCodes={courseCodes} />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseViewAssessmentModal} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* studyplan model */}

            <Dialog open={openViewStudyPlanModal}
                onClose={handleCloseViewStudyPlanModal}
                fullWidth
                maxWidth="md"
                id="modalId"

            >
                <DialogTitle>
                    <div style={{ display: "flex", justifyContent: "space-between" }}><span>Study Plan</span></div>
                </DialogTitle>
                <DialogContent className="surveyData">

                    <Grid container spacing={2} sx={{ padding: 0 }} >
                        <Grid item xl={12} lg={12} md={12} sx={{ mt: 2 }}>

                            <Table style={{ border: "1px solid grey" }}>
                                <tr style={{ border: "1px solid grey" }}>
                                    <th style={{ border: "1px solid grey" }}>Study Plan Title</th>
                                    <td style={{ border: "1px solid grey" }} colSpan={6}>{studyPlanData?.title}</td>
                                </tr>
                                <tr>
                                    <th style={{ border: "1px solid grey" }}>Credit Hours</th>
                                    <td style={{ border: "1px solid grey" }} colSpan={2}>{studyPlanData?.creditHours}</td>
                                    <th style={{ border: "1px solid grey" }}>Approval date</th>
                                    <td style={{ border: "1px solid grey" }}> {studyPlanData?.approvalDate}</td>
                                    <th style={{ border: "1px solid grey" }}>Active</th>
                                    <td style={{ border: "1px solid grey" }}>True</td>
                                </tr>
                                <tr>
                                    <th style={{ border: "1px solid grey" }}>Program Name</th>
                                    <td style={{ border: "1px solid grey" }} colSpan={6}>{programMap[studyPlanData?.programId]}</td>
                                </tr>
                            </Table>

                            <Table style={{ marginTop: "20px" }}>
                                <tr>
                                    <th style={{ border: "1px solid grey" }}>College Name</th>
                                    <th style={{ border: "1px solid grey" }}>Campus Name</th>
                                    <th style={{ border: "1px solid grey" }}>Department Name</th>
                                </tr>

                                {studyPlanData?.offeredProgram.map((program: any) => (
                                    <tr >
                                        <td style={{ border: "1px solid grey" }}>
                                            {collegeMap[program.collegeId]}
                                        </td>
                                        <td style={{ border: "1px solid grey" }}>
                                            {campusMap[program.campusId]}
                                        </td>
                                        <td style={{ border: "1px solid grey" }}>
                                            {departmentMap[program.departmentId]}
                                        </td>
                                    </tr>
                                ))}

                            </Table>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseViewStudyPlanModal} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>




            {/* change password modal */}

            <Dialog open={openViewPasswordChange}
                onClose={handleCloseChangePasswordModal}
                fullWidth
                maxWidth="md"
                id="modalId"

            >
                <DialogTitle>
                    <div style={{ display: "flex", justifyContent: "space-between" }}><span>Change Password</span></div>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ padding: 0 }}>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label="New Password"
                                type="password"
                                value={newPassword}
                                onChange={handleNewPasswordChange}
                                error={!!passwordError}
                                helperText={passwordError}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label="Confirm Password"
                                type="password"
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                error={!!passwordError}
                                helperText={passwordError}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseChangePasswordModal} color="primary">
                        Close
                    </Button>
                    <Button onClick={handleSubmitChangedPassword} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>


            {/* goal modal */}

            <ViewGoalModal
                openViewGoalModal={openViewGoalModal}
                currentLang={currentLang}
                setOpenViewGoalModal={setOpenViewGoalModal}
                goalData={goalData}
            />



            <SnackBar
                isOpen={snackBarHandler.open}
                message={snackBarHandler.message}
                severity={snackBarHandler.severity}
                setIsOpen={
                    // Only pass the setIsOpen function to the SnackBar component
                    // and not the whole state object
                    (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                }
            />

            <EditTableModal
                openUpdateTableModal={openModal}
                setOpenUpdateTableModal={setOpenModal}
                originalValues={originalValues}
                columnName={columnName}
                url={URL}
                currentRowIndex={currentRowIndex}
                studyPlanOfferedProgram={studyPlanOfferedProgram}
                goalDatas={goalDatas}
                goalMappingToKpi={goalMappingToKpi}
                assignTaskTasks={assignTaskTasks}
                tableData={tableData}
                setTableData={setTableData}
                setFetchUpdate={setFetchUpdate}
                setForUpdate={setForUpdate}
            />
        </div>
    );
};

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal: FC<{
    columns: MRT_ColumnDef<any>[];
    onClose: () => void;
    onSubmit: (values: any) => void;
    open: boolean;
    buttonTitle?: string;
}> = ({ open, columns, onClose, onSubmit, buttonTitle }) => {
    const [values, setValues] = useState<any>(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {} as any),
    );

    const handleSubmit = () => {
        //put your validation logic here
        onSubmit(values);
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">{buttonTitle}</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {columns.map((column) => (
                            <TextField
                                // @ts-ignore
                                key={column.accessorKey}
                                // @ts-ignore
                                label={column.header}
                                // @ts-ignore
                                name={column.accessorKey}
                                onChange={(e) =>
                                    setValues({ ...values, [e.target.name]: e.target.value })
                                }
                            />
                        ))}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    Create New Account
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const validateRequired = (value: string) => !!value.length;
const validateEmail = (email: string) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
const validateAge = (age: number) => age >= 18 && age <= 50;

export default memo(CustomTableCrud);