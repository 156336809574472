const initialState = {
    data: null,
    lastSelectedCollege:null,
  };
  
  const sessionSectionStatisticReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case 'SCHEDULE_SECTION_STATISTIC_DATA':
        return {
          ...state,
          data: action.payload.data,
          lastSelectedCollege: action.payload.data.college,
        };
    //   case 'UPDATE_SCHEDULE_SECTION_STATISTIC_DATA':
    //     return {
    //         ...state,
    //         data: {
    //             ...state.data || {},
    //             college: action.payload, // Update only the `college` part
    //         },
    //     }
      case 'UPDATE_SCHEDULE_SECTION_STATISTIC_DATA':
        return {
            ...state,
            lastSelectedCollege: action.payload, 
        }
      case 'LOGOUT':
        return initialState;
      default:
        return state;
    }
  };
  
  export default sessionSectionStatisticReducer;