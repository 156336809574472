import { combineReducers } from 'redux';
import authReducer from './authReducer';
import userDataReducer from './userDataReducer';
import tableDataReducer from './tableDataReducer';
import eqaFormReducer from './eqaFormReducer';
import assignTaskReducer from './assignTaskReducer';
import userSignatureReducer from './userSignatureReducer';
import userPictureReducer from './userPictureReducer';
import campusByLoggedinUserReducer from './campusByLoggedinUserReducer';
import collegeReducer from './collegeReducer';
import campusReducer from './campusReducer';
import departmentReducer from './departmentReducer';
import programReducer from './programReducer';
import sessionTypeListReducer from './sessionTypeListData';
import asessmentTypeReducer from './assessmentTypeReducer';
//import assessmentByLoggedInUserReducer from './assessmentByLoggedInUserReducer';
import offeredCoursesReducer from './offeredCoursesReducer';
import courseReducer from './courseReducer';
import userListDataReducer from './userListDataReducer';
import driveFileListReducer from './driveFileListReducer';
import driveDirectoryListReducer from './driveDirectoryListReducer';
import meetingReducer from './meetingReducer';
import portalReducer from './portalReducer';
import surveyReducer from './surveyReducer';
import scheduleCourseReducer from './scheduleCourseReducer';
import scheduleSectionStatisticReducer from './scheduleSectionStatisticReducer'
import systemPreferencesReducer from './systemPreferencesReducer';
import studyPlanReducer from './studyPlanReducer';
import committeeReducer from './committeeReducer';
import tajweedReducer from './tajweeCoreReducer';
import manageCourseReducer from './manageCourseReducer';
import accountReducer from './accountReducer';
import spsReducer from './spsReducer';
import qmsReducer from './qmsReducer';
import assessmentReducer from './assessmentReducer';
import activeAcademicYearReducer from './activeAcademicYearReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  userData: userDataReducer,
  eqaForm: eqaFormReducer,
  tableData: tableDataReducer,
  assignTaskData: assignTaskReducer,
  userSignatureData: userSignatureReducer,
  userPictureData: userPictureReducer,
  loggedInUserInfo: campusByLoggedinUserReducer,
  collegeListData: collegeReducer,
  campusListData: campusReducer,
  departmentListData: departmentReducer,
  programListData: programReducer,
  sessionTypeListData: sessionTypeListReducer,
  assessmentTypeListData: asessmentTypeReducer,
  //assessmentByLoggedInUserData: assessmentByLoggedInUserReducer,
  offeredCourseListData: offeredCoursesReducer,
  courseData: courseReducer,
  userListData : userListDataReducer,
  driveFileList: driveFileListReducer,
  driveDirectoryList: driveDirectoryListReducer,
  meetings: meetingReducer,
  portal: portalReducer,
  survey: surveyReducer,
  scheduleCourse: scheduleCourseReducer,
  scheduleSectionStatistic:scheduleSectionStatisticReducer,
  systemPreferences: systemPreferencesReducer,
  studyPlan: studyPlanReducer,
  committee: committeeReducer,
  tajweedCore: tajweedReducer,
  manageCourse: manageCourseReducer,
  account: accountReducer,
  sps: spsReducer,
  qms: qmsReducer,
  assessment: assessmentReducer,
  activeAcademicYear: activeAcademicYearReducer,
});

export default rootReducer;
