import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import axios from "axios";
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";
import { MASTER_X_API_KEY, UNIVERSITY_NAME } from "../../../../constants";
import 'jspdf-autotable';
import {
    Box,
    Typography,
    Grid,
    MenuItem as MUIMenuItem,
    RadioGroup, FormControlLabel, Radio, FormControl
} from "@mui/material";
import styles from "./style.module.css";
import Table from '@mui/material/Table';
import { Skeleton } from "@mui/material";
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IOfferedCourse, ICourseCLO } from "../../../report/model/SOEvidence";
import createAPI from "../../data/api/create";
import SnackBar from "../../../../Components/SnackBar";
import useFetchCourseInfo from "../customHooks/useFetchCourseInfo";
import { blue } from "@mui/material/colors";
import InternetConnectionStatus from "../../../../Components/SharedComponents/InternetConnectionStatus";


interface SurveyFormProps {
    isMinified: Boolean,
    setIsMinified: any,
    currentLang: string,
    setCurrentLang: any,
}

const SurveyForm: React.FC<SurveyFormProps> = ({
    isMinified,
    setIsMinified,
    currentLang,
    setCurrentLang,
}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);



    type StateCourseCodeListType = {
        data: IOfferedCourse[],
        errMessage: string
    }

    type StateCourseCLOType = {
        data: ICourseCLO[],
        errMessage: string
    }


    const [snackBarHandler, setSnackBarHandler] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

   
    const [courseCode, setCourseCode] = useState<any>(null);
    const [section,setSection] = useState<string>("");
    const [surveyId,setSurveyId] = useState<number>(0);

    const { restUrl } = useParams<{ restUrl: string }>() as { restUrl: string };
    // To track if the initial URL parsing has been done
    const [isUrlParsed, setIsUrlParsed] = useState(false);

    // extract courseCode, section,surveyId from restUrl
    useEffect(() => {
       if (restUrl) {
            const coureCodePart = restUrl.split('_')[0]
            setSection(restUrl.split('_')[1]);
            setSurveyId(Number(restUrl.split('_').pop()));
            setCourseCode(coureCodePart)
            setIsUrlParsed(true);
        }
    }, [restUrl])

   const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    //for window resize
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });


    const handleClickInstruction = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let accessToken = MASTER_X_API_KEY
    const { courseInfo, isCourseLoadingStatus } = useFetchCourseInfo(courseCode,surveyId);
    const [selectedValue, setSelectedValue] = useState<any>({});
    const [errors, setErrors] = useState(Array(courseInfo?.data?.courseCLO?.length).fill(''));


    const handleRadioChange = (index: any, value: any) => {
        setSelectedValue((prevState: any) => {
            return {
                ...prevState, // Spread the previous state object
                [index]: value // Update the value at the specified index
            };
        });

        // Clear the error message for this row when the user selects a value
        setErrors(prevState => {
            const newErrors = [...prevState];
            newErrors[index] = ''; // Clear error for this row
            return newErrors;
        });
       
    };

    const extractNumericPart = (cloId: any) => {
        return cloId.replace(/^\D+/g, '');
    };

    const handleSubmitSurveyForm = () => {
        // Validation checks
        let isValid = true;
        const newErrors = Array(courseInfo?.data?.courseCLO?.length).fill('');

        courseInfo?.data?.courseCLO?.forEach((item, index) => {
            if (selectedValue[index] === undefined) {
                newErrors[index] = 'Please select hilighted clo value';
                isValid = false;
            }
        });

        setErrors(newErrors);

        if (!isValid) {
            // console.error("Please select a value for each item.");
            return;
        }

        // Clear errors when the form is submitted

        // Prepare form data
        const formData = courseInfo?.data?.courseCLO?.map((item: any, index: any) => ({
            surveyId: surveyId,
            cloId: extractNumericPart(item.cloId),
            value: selectedValue[index]
        }));
        const payload = { "payload": formData }
        axios.post(createAPI.postSurveyFormData,
            payload
            , {
                headers: {
                    'x-api-key': accessToken,
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": '*'
                }
            })
            .then(function (response) {
                if (response.status === 201) {
                    setSnackBarHandler({
                        open: true,
                        message: (response.data.code === "201") ? (`Survey Data has been Submitted Successfully`) : (response.data.message),
                        severity: (response.data.code === "201") ? ("success") : ("error")
                    })
                    const m = response.data.message;
                    
                    if (response.data.code === "201") {
                        navigate("/survey/thankyou")
                        // setFetchUpdate(true);
                        setTimeout(() => {
                            // refreshFun(formState);
                        }, 3000);
                    }
                }
            })
            .catch(function (error) {
                navigate("/survey/completed")
                setSnackBarHandler({
                    open: true,
                    message: error.message,
                    severity: "error"
                })
            });
    }

    return (
        <div
            className={styles.container}
        >
            <Box
                sx={{
                    padding:
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (0),
                    boxShadow: "0 0 10px grey",
                    backgroundColor: "rgba(211, 211, 211, 0.3)",
                    marginTop: 5, marginBottom: 10,
                    borderRadius: "20px"
                }}
            >


                <Box sx={{ backgroundColor: "white", flexGrow: 1, padding: 5, flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row") }} >
                    <Grid container sx={{ paddingLeft: 3 }} spacing={
                        (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                    } dir={(currentLang === "ar") ? ('rtl') : ('ltr')}>

                        {/* Survey Heading */}
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Box sx={{ flexGrow: 1, padding: 0 }}>
                                                <Grid container spacing={
                                                    (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                                                }>

                                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ display: 'flex' }}
                                                        dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                                                    >
                                                        <img
                                                            className={styles.universityLogo}
                                                            width={80}
                                                            height={122}
                                                            src={require(`../../../../assets/Images/universityLogo/taifuniversitylogo.jpeg`)}
                                                            alt="University Logo"
                                                        />
                                                        <div className={styles.collegeNameHeading}>
                                                            { isCourseLoadingStatus ? (
                                                                    <>
                                                                        <h2>{courseInfo?.data?.departmentName}</h2>
                                                                        <span>{courseInfo?.data?.collegeName}</span><br />
                                                                        <span>{UNIVERSITY_NAME}</span>
                                                                    </>
                                                                
                                                                ):(
                                                                    <>
                                                                        <h2><Skeleton animation="wave" width={"300px"} height={"31px"} /></h2>
                                                                        <span><Skeleton animation="wave" width={"300px"} height={"31px"} /></span>
                                                                        <span>{UNIVERSITY_NAME}</span>
                                                                    </>
                                                                )
                                                            } 
                                                        </div>

                                                    </Grid>
                                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ textDecoration: "underline", textAlign: "center" }}>
                                                        {/* <h4>Course Survey - Fall 2023</h4> */}
                                                        { isCourseLoadingStatus ? (
                                                                <>
                                                                    <h4>Course Survey - {courseInfo?.data?.semester} {courseInfo?.data?.year}</h4>
                                                                </>
                                                            
                                                            ):(
                                                                <>
                                                                    <h4 style={{ display: 'inline-block' }}><Skeleton animation="wave" width={"500px"} height={"29px"} /></h4>
                                                                </>
                                                            )
                                                        } 
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                            <InternetConnectionStatus />
                        </Grid>
                        {/* Course Detail table */}
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ border: "5px solid #3c6766", width: "50px", height: "auto" }}>
                            <p className={styles.labelFont}>
                                <span style={{ marginRight: "200px" }}>{t('Home.Sidebar.list.report.subMenu.SOEvidence.details.courseCode')}:</span>
                               <span>{courseCode}</span>
                            </p>

                            <p className={styles.labelFont}>
                                <span style={{ marginRight: "194px" }}>{t('Home.Sidebar.list.report.subMenu.SOEvidence.details.courseName')}:</span>
                                <span style={{ display: 'inline-block' }}>{
                                        isCourseLoadingStatus ? (
                                            courseInfo?.data?.courseName
                                        ):(
                                            <Skeleton animation="wave" width={"300px"} height={"10px"} />
                                        )
                                    }
                                </span>
                             </p>

                            <p className={styles.labelFont}>
                                <span style={{ marginRight: "235px" }}>{t('Home.Sidebar.list.report.subMenu.SOEvidence.details.section')}:</span>
                                <span>{section}</span>
                            </p>

                        </Grid>

                        {/* Survey information */}
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ border: "5px solid #3c6766", width: "100%", height: "auto", marginTop: "10px" ,paddingTop:"0px",verticalAlign:"center"}}>
                                <p style={{padding:"0px",fontSize:18}}>This course has certain learning outcomes. The purpose of this survey is to get your input on.</p>
                                <h4>How well you have learned / mastered these course learning outcomes.</h4>
                                <p style={{paddingTop:"5px",marginBottom:"20px",fontSize:18}}>This survey evaluates the CLO's, NOT the course Instructor.</p>
                        </Grid>
                        {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ border: "5px solid #3c6766", width: "100%", height: "auto", display: "flex", marginTop: "10px" }}>
                            <Grid style={{ flex: 1}}>
                                <span>This course has certain learning outcomes. The purpose of this survey is to get your input on.</span>
                                <h4>How well you have learned / mastered these course learning outcomes.</h4>
                                <span>This survey evaluates the CLO's, NOT the course Instructor.</span>
                            </Grid>
                            <Grid style={{ flex: 1, marginLeft: "10px"}}>
                                 <span>This course has certain learning outcomes. The purpose of this survey is to get your input on.</span>
                                <h4>How well you have learned / mastered these course learning outcomes.</h4>
                                <span>This survey evaluates the CLO's, NOT the course Instructor.</span>
                            </Grid> 
                        </Grid> */}

                        {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ border: "5px solid #3c6766", width: "100%", height: "auto", display: "flex", marginTop: "10px" }}>
                            <Grid style={{ flex: 1}}>
                                 <span>This course has certain learning outcomes. The purpos of this survey is to get your input on.</span>
                            </Grid>
                            <Grid style={{ flex: 1, marginLeft: "10px" }}>
                                <span>This course has certain learning outcomes. The purpos of this survey is to get your input on.</span>
                            </Grid>
                        </Grid> */}

                        {/*  CLOs Survey table */}
                        <TableContainer component={Paper} className={styles.tablecontainer}>
                            <Table className={styles.customtable}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: "5%" }}><span className={styles.labelFont}>{t('Home.Sidebar.list.survey.surveySetting.details.form.clo')}</span></TableCell>
                                        <TableCell style={{ width: "58%" }}><span className={styles.labelFont}>{t('Home.Sidebar.list.survey.surveySetting.details.form.description')}</span></TableCell>
                                        <TableCell style={{ width: "9%" }}><span className={styles.labelFont}>{t('Home.Sidebar.list.survey.surveySetting.details.form.stronglyDisagree')}</span></TableCell>
                                        <TableCell style={{ width: "7%" }}><span className={styles.labelFont}>{t('Home.Sidebar.list.survey.surveySetting.details.form.disagree')}</span></TableCell>
                                        <TableCell style={{ width: "7%" }}><span className={styles.labelFont}>{t('Home.Sidebar.list.survey.surveySetting.details.form.neutral')}</span></TableCell>
                                        <TableCell style={{ width: "7%" }}><span className={styles.labelFont}>{t('Home.Sidebar.list.survey.surveySetting.details.form.agree')}</span></TableCell>
                                        <TableCell style={{ width: "7%" }}><span className={styles.labelFont}>{t('Home.Sidebar.list.survey.surveySetting.details.form.stronglyAgree')}</span></TableCell>
                                    </TableRow>
                               
                                </TableHead>
                                {isCourseLoadingStatus ?
                                    (<TableBody>
                                        {courseInfo?.data?.courseCLO?.map((item, index) => (
                                            <TableRow key={index} style={{height: "90px"}}>
                                                <TableCell style={errors[index] ? { backgroundColor: '#ECECEC' } : {}}>{item.cloId}<br />{errors[index] && <span style={{ color: 'red' }}>{errors[index]}</span>}</TableCell>
                                                <TableCell style={errors[index] ? { backgroundColor: '#ECECEC' } : { width: "600px" }}>{item.description}</TableCell>
                                                <TableCell className={styles.tableStyle} style={errors[index] ? { backgroundColor: '#ECECEC'} : { textAlign:"center"}} >
                                                    <FormControl component="fieldset">
                                                        <RadioGroup
                                                            name={`radioGroup-${index}`}
                                                            value={selectedValue[index] || ''}
                                                            onChange={(e) => handleRadioChange(index, e.target.value)}
                                                           
                                                        >
                                                            <FormControlLabel
                                                                value="1"
                                                                control={<Radio />}
                                                                label=""
                                                                
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell className={styles.tableStyle} style={errors[index] ? { backgroundColor: '#ECECEC' } : {textAlign:"center"}}>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup
                                                            name={`radioGroup-${index}`}
                                                            value={selectedValue[index] || ''}
                                                            onChange={(e) => handleRadioChange(index, e.target.value)}
                                                        >
                                                            <FormControlLabel
                                                                value="2"
                                                                control={<Radio />}
                                                                label=""
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell className={styles.tableStyle} style={errors[index] ? { backgroundColor: '#ECECEC' } : {textAlign:"center"}}>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup
                                                            name={`radioGroup-${index}`}
                                                            value={selectedValue[index] || ''}
                                                            onChange={(e) => handleRadioChange(index, e.target.value)}
                                                        >
                                                            <FormControlLabel
                                                                value="3"
                                                                control={<Radio />}
                                                                label=""
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell className={styles.tableStyle} style={errors[index] ? { backgroundColor: '#ECECEC' } : {textAlign:"center"}}>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup
                                                            name={`radioGroup-${index}`}
                                                            value={selectedValue[index] || ''}
                                                            onChange={(e) => handleRadioChange(index, e.target.value)}
                                                        >
                                                            <FormControlLabel
                                                                value="4"
                                                                control={<Radio />}
                                                                label=""
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </TableCell>

                                                <TableCell className={styles.tableStyle} style={errors[index] ? { backgroundColor: '#ECECEC' } : {textAlign:"center"}}>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup
                                                            name={`radioGroup-${index}`}
                                                            value={selectedValue[index] || ''}
                                                            onChange={(e) => handleRadioChange(index, e.target.value)}
                                                        >
                                                            <FormControlLabel
                                                                value="5"
                                                                control={<Radio />}
                                                                label=""
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </TableCell>
                                            </TableRow>


                                        ))}
                                    </TableBody>
                                    ):(
                                        Array(5)
                                        .fill(0)
                                        .map((_, index) =>
                                        <TableBody>
                                            <TableRow key={index} style={{height: "98px"}}>
                                            <TableCell><Skeleton variant="text" width={"5%"}/></TableCell>
                                            <TableCell><Skeleton animation="wave" width={"58%"} /></TableCell>
                                            <TableCell><center><Skeleton animation="wave" width={"9%"}/></center></TableCell>
                                            <TableCell><center><Skeleton animation="wave" width={"7%"}/></center></TableCell>
                                            <TableCell><center><Skeleton animation="wave" width={"7%"}/></center></TableCell>
                                            <TableCell><center><Skeleton animation="wave" width={"7%"}/></center></TableCell>
                                            <TableCell><center><Skeleton animation="wave" width={"7%"}/></center></TableCell>
                                            </TableRow>
                                        </TableBody>
                                        )
                                    )
                                }
                            </Table>
                        </TableContainer>
                    </Grid>

                    {/* Submit button */}
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                            <Button
                                dir={currentLang === "ar" ? "rtl" : "ltr"}
                                variant="contained"
                                sx={{
                                    backgroundColor: "#d5b254",
                                    fontWeight: "bold",
                                    height: 40,
                                    mt: 2,
                                    "&:hover": {
                                        backgroundColor: "#d5b254",
                                    },
                                }}
                                fullWidth
                                onClick={handleSubmitSurveyForm}
                            >
                                <Typography style={{ display: "block" }} dir={currentLang === "ar" ? "rtl" : "ltr"}>
                                    {t('Home.Sidebar.list.survey.surveySetting.details.button.submit')}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />
            </Box>
        </div>
    )
}
export default SurveyForm;