import axios from 'axios';
import axiosInstance from './interceptor';
import readAPI from '../Data/API/READ';
import { IProgram, IDepartment, ICampus, ICollege, IUsersType, ICommitteeType, IAcademicYear } from '../Model/Global'

export type ProgramDetails = {
    data: IProgram[],
    errMessage: string
}

export type DepartmentDetails = {
    data: IDepartment[],
    errMessage: string
}

export type CampusDetails = {
    data: ICampus[],
    errMessage: string
}

export type CollegeDetails = {
    data: ICollege[],
    errMessage: string
}

export type UserDetails = {
    data: IUsersType[],
    errMessage: string
}
export type CommitteeDetails = {
    data: ICommitteeType[],
    errMessage: string
}

export type AcademicYear = {
    data : IAcademicYear,
    errMessage: string
}

//@ Function to fetch all program

async function getProgram(accessToken: string): Promise<ProgramDetails> {
    let url: string = readAPI.Program;
    let header: string = accessToken;
    let programDetails: ProgramDetails = {
        data: [],
        errMessage: ""
    }
    await axios.get(url, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            programDetails.data = res.data.obj;
            if (res.data.code !== "200.200") {
                programDetails.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            programDetails.errMessage = err.message;
        })
    return programDetails;
}

//@Function to fetch all department
async function getDepartment(accessToken: string): Promise<DepartmentDetails> {
    let url: string = readAPI.Department;
    let header: string = accessToken;
    let departmentDetails: DepartmentDetails = {
        data: [],
        errMessage: ""
    }
    await axios.get(url, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            departmentDetails.data = res.data.obj;
            if (res.data.code !== "200.200") {
                departmentDetails.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            departmentDetails.errMessage = err.message;
        })
    return departmentDetails;
}

//@Function to fetch all campus
async function getCampus(accessToken: string): Promise<CampusDetails> {
    let url: string = readAPI.Campus;
    let header: string = accessToken;
    let campusDetails: CampusDetails = {
        data: [],
        errMessage: ""
    }
    await axios.get(url, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            campusDetails.data = res.data.obj;
            if (res.data.code !== "200.200") {
                campusDetails.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            campusDetails.errMessage = err.message;
        })
    return campusDetails;
}

//@Function to fetch all college
async function getCollege(accessToken: string): Promise<CollegeDetails> {
    let url: string = readAPI.College;
    let header: string = accessToken;
    let collegeDetails: CollegeDetails = {
        data: [],
        errMessage: ""
    }
    await axios.get(url, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            collegeDetails.data = res.data.obj;
            if (res.data.code !== "200.200") {
                collegeDetails.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            collegeDetails.errMessage = err.message;
        })
    return collegeDetails;
}


async function getUsers(accessToken: string): Promise<UserDetails> {
    let url: string = readAPI.Users;
    let header: string = accessToken;
    let userDetails: UserDetails = {
        data: [],
        errMessage: ""
    }
    await axios.get(url, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            userDetails.data = res.data.obj;
            if (res.data.code !== "200.200") {
                userDetails.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            userDetails.errMessage = err.message;
        })
    return userDetails;
}

async function getCommittee(accessToken: string): Promise<CommitteeDetails> {
    let url: string = readAPI.Committee;
    let header: string = accessToken;
    let committeeDetails: CommitteeDetails = {
        data: [],
        errMessage: ""
    }
    await axios.get(url, {
        headers: {
            "x-api-key": header,
            "Accept-Language": "en"
        }
    })
        .then((res) => {
            committeeDetails.data = res.data.obj.content;
            if (res.data.code !== "200.200") {
                committeeDetails.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            committeeDetails.errMessage = err.message;
        })
    return committeeDetails;
}

async function getActiveAcademicYear(): Promise<AcademicYear> {
    let url: string = readAPI.getActiveAcademicYear;
    // let header: string = accessToken;
    let academicYear: AcademicYear = {
        data: {} as IAcademicYear,
        errMessage: ""
    }
    await axiosInstance.get(url)
        .then((res) => {
            academicYear.data = res.data.obj;
            // console.log("From global SErvice : " + JSON.stringify(academicYear));
            if (res.data.code !== "200.200") {
                academicYear.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            academicYear.errMessage = err.message;
        })
    return academicYear;
}


export const GlobalService = {
    getProgram,
    getDepartment,
    getCampus,
    getCollege,
    getUsers,
    getCommittee,
    getActiveAcademicYear
}